import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Input, Space, NewTypography, Flex, tokens, NewModal, NewButton as Button } from '@unitoio/mosaic';

import { getScripts } from '~/reducers';
import { UnitoPrompt } from '~/components/SiteAdminSearch/UnitoPrompt';
import { useDebounce } from '~/hooks';

export const SiteAdminTool = ({ setSiteAdminSearchString, siteAdminSearchString }) => {
  const [showUnitoPrompt, setShowUnitoPrompt] = useState(false);
  const scripts = useSelector(getScripts);

  const handleSiteAdminSearch = useDebounce((e) => {
    setSiteAdminSearchString(e.target.value);
  }, 500);

  return (
    <Space
      style={{
        display: 'block',
        margin: `0 0 ${tokens.spacing.s4}`,
        padding: `${tokens.spacing.s1} ${tokens.spacing.s4} ${tokens.spacing.s4}`,
        background: tokens.colors.background.primary.action,
      }}
    >
      <NewModal
        width={900}
        open={showUnitoPrompt}
        centered
        onCancel={() => setShowUnitoPrompt(false)}
        onOk={() => setShowUnitoPrompt(false)}
      >
        <UnitoPrompt scripts={scripts} />
      </NewModal>

      <NewTypography.Title level={4}>Site Admin Tool</NewTypography.Title>
      <Flex style={{ width: '100%' }}>
        <Input.Search
          style={{ marginRight: tokens.spacing.s3 }}
          placeholder="Search links by LinkId, OrganizationId, MultisyncId or UserId. Separate multiple searches with commas."
          onChange={handleSiteAdminSearch}
          defaultValue={siteAdminSearchString}
          allowClear
        />
        <Button onClick={() => setShowUnitoPrompt(true)} type="primary">
          Unito Prompt
        </Button>
      </Flex>
    </Space>
  );
};

SiteAdminTool.propTypes = {
  siteAdminSearchString: PropTypes.string.isRequired,
  setSiteAdminSearchString: PropTypes.func.isRequired,
};
