import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Input, tokens, Box } from '@unitoio/mosaic';

const { Search } = Input;
const SearchInputWrapper = styled(Box)`
  max-width: 35%;
`;

export const SyncListSearchInputRevamp = ({ isLoading = false, searchString = '', onSearch }) => (
  <SearchInputWrapper m={[tokens.spacing.s4, 0]}>
    <Search
      key={searchString}
      allowClear
      defaultValue={searchString}
      loading={isLoading}
      onSearch={onSearch} // onclick search-icon/clear-icon or pressing Enter key
      placeholder="Search for a flow"
    />
  </SearchInputWrapper>
);

SyncListSearchInputRevamp.propTypes = {
  isLoading: PropTypes.bool,
  searchString: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
};
