import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link as ReactRouterLink } from 'react-router-dom';

import { Box, Link, tokens, Typography } from '@unitoio/mosaic';

import * as featureTypes from '~/consts/features';
import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import { getChangesSyncedLast30dByOrganizationId } from '~/reducers';
import { useTrackEvent } from '~/hooks/useTrackEvent';

import { FeatureLimit } from './FeatureLimit';

const PullRightBox = styled(Box)`
  margin-left: auto;
`;

const FeatureLimitSeparator = styled.span`
  margin: 0 ${tokens.spacing.s3};
`;

export function ChangesCard() {
  const { organizationId } = useParams();
  const trackEvent = useTrackEvent();
  const changesSyncedLast30d = useSelector((state) => getChangesSyncedLast30dByOrganizationId(state, organizationId));

  return (
    <Box
      flexDirection="row"
      p={[tokens.spacing.s6, tokens.spacing.s5]}
      borderColor={tokens.colors.content.neutral.n10}
      borderRadius={tokens.spacing.s4}
    >
      <Box flexDirection="row" alignItems="baseline">
        <Typography variant="h2">{changesSyncedLast30d}</Typography> <FeatureLimitSeparator>/</FeatureLimitSeparator>
        <FeatureLimit featureName={featureTypes.FEATURES.CHANGES} />
      </Box>
      <PullRightBox
        p={[tokens.spacing.s2, 0]}
        onClick={() =>
          trackEvent(trackingTypes.ACTION, {
            action_name: trackingTypes.USAGE_EVENTS.ACTIONS.SEE_ALL_CHANGES,
          })
        }
      >
        <Link as={ReactRouterLink} to={routes.ABSOLUTE_PATHS.ACTIVITY_LOGS}>
          See changes
        </Link>
      </PullRightBox>
    </Box>
  );
}
