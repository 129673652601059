import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { Modal } from '@unitoio/mosaic';
import * as trackingTypes from '~/consts/tracking';
import { useLogger } from '~/hooks/useLogger';
import { useTrackEvent } from '~/hooks/useTrackEvent';

import { CheckoutForm } from '../CheckoutForm/CheckoutForm';

const stripePromise = loadStripe(import.meta.env.VITE_UNITO_STRIPE_PUBLISHABLE_KEY);

export function ChangeCreditCardModal({ isOpen, onCancel, onSuccess }) {
  const trackEvent = useTrackEvent();
  const { reportException } = useLogger();
  useEffect(() => {
    if (!stripePromise) {
      reportException('Stripe publishable key is undefined', { identifier: 'loadStripe ChangeCreditCardModal' });
    }
    trackEvent(`${trackingTypes.MODULE.CHANGE_CREDIT_CARD}_${trackingTypes.START}`, {});
  }, [trackEvent, reportException]);

  return (
    <Elements stripe={stripePromise}>
      <Modal isOpen={isOpen} onCancel={onCancel} onRequestClose={onCancel} title="Edit payment details" hideButtons>
        <CheckoutForm trackEventNameContext={trackingTypes.MODULE.CHANGE_CREDIT_CARD} onSuccess={onSuccess} />
      </Modal>
    </Elements>
  );
}

ChangeCreditCardModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
