import React from 'react';
import PropTypes from 'prop-types';

import { Box, tokens, Typography } from '@unitoio/mosaic';

import { Button } from '~/components/Button/Button';
import { Section } from '~/components/Section/Section';

import { AuthButtonsWrapper } from '../../../AuthButtonsWrapper';

export function AuthAirtableOAuth2Step1({ children: instructionFields, onSubmit, onCancel }) {
  return (
    <div className="auth-instruction-step">
      <Box m={[0, 0, tokens.spacing.s4, 0]}> </Box>
      <Section>
        <Typography variant="body1">
          Airtable's API does not provide the email address so please add it below:
        </Typography>
        <Box m={[0, 0, tokens.spacing.s4, 0]}> </Box>
        {instructionFields}
        <AuthButtonsWrapper className="clearfix">
          <Button btnStyle="dark" onClick={onSubmit} type="href" pullRight>
            Authorize
          </Button>
          <Button reverse btnStyle="dark" onClick={onCancel}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </Section>
    </div>
  );
}

AuthAirtableOAuth2Step1.propTypes = {
  children: PropTypes.node,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
