import React from 'react';
import PropTypes from 'prop-types';

import { Box, Link, tokens, Typography, NewAlert as Alert, NewAlertLevel } from '@unitoio/mosaic';

import { Button } from '~/components/Button/Button';
import { Section } from '~/components/Section/Section';

import { AuthButtonsWrapper } from '../../../AuthButtonsWrapper';

import { AuthOrderedList } from '../../AuthOrderedList';
import { AuthImageWrapper } from '../../AuthImageWrapper';
import favroApiKey from './favro-api-key.png';

export function AuthFavroApiTokenStep1({ children: instructionFields, onSubmit, onCancel }) {
  return (
    <div className="auth-instruction-step">
      <Box m={[0, 0, tokens.spacing.s4, 0]}> </Box>
      <Box m={[tokens.spacing.s4, 0]}>
        <Alert
          level={NewAlertLevel.INFO}
          message={
            <>
              In order for tasks to sync properly with Unito,{' '}
              <Link target="_blank" href="https://www.favro.com/pricing">
                Favro requires you to have, at least, a Standard plan
              </Link>
              . Trial or Lite accounts won't support the integration.
            </>
          }
        />
      </Box>
      <Section>
        <Typography variant="body1">
          Before continuing, you'll need to generate an API Token to allow Unito to properly connect to Favro.
        </Typography>
        <AuthImageWrapper>
          <img src={favroApiKey} alt="Favro API Token" />
        </AuthImageWrapper>
        <AuthOrderedList>
          <li>
            <Typography variant="body1">
              Go to your
              <Link target="_blank" href="https://favro.com">
                Favro Account
              </Link>
              , navigate to your profile and scroll to the API section
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Type Unito on the empty field and click on <strong>Create token</strong>
            </Typography>
          </li>
          <li>
            <Typography variant="body1">Copy the id generated and past it below</Typography>
          </li>
        </AuthOrderedList>
        <Typography variant="body1">
          <strong>Note</strong>: If an API Token has already been generated, you can use it with Unito as well.{' '}
        </Typography>
        <Box m={[0, 0, tokens.spacing.s4, 0]}> </Box>
        {instructionFields}
        <AuthButtonsWrapper className="clearfix">
          <Button btnStyle="dark" onClick={onSubmit} type="href" pullRight>
            Authorize
          </Button>
          <Button reverse btnStyle="dark" onClick={onCancel}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </Section>
    </div>
  );
}

AuthFavroApiTokenStep1.propTypes = {
  children: PropTypes.node,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
