import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Box, Typography } from '@unitoio/mosaic';

import { AuthButtonsWrapper } from '../../../AuthButtonsWrapper';
import { AuthImageWrapper } from '../../AuthImageWrapper';
import mondayInstallation from './monday-installation.svg';

const InstallationButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10%;
  margin-bottom: 10%;
`;

const PullRightButton = styled.div`
  float: right;
`;

export function AuthMondayInstallationStep1({ children: instructionFields, onSubmit, onCancel }) {
  return (
    <div className="auth-instruction-step">
      <Box>
        <Typography variant="body1">
          Before you connect, you'll need to{' '}
          <strong>install the 2-way syncing with Unito app in your monday.com workspace via the button below </strong>.
          If you are not an admin of you workspace, you will be prompted to send an admin a request to install the app
          for the entire workspace.
        </Typography>
        <AuthImageWrapper>
          <img src={mondayInstallation} alt="Favro API Token" />
        </AuthImageWrapper>
        <InstallationButtonWrapper>
          <Button onClick={() => window.open('https://monday.com/marketplace/11', '_blank')}>
            Install the Unito app for monday.com
          </Button>
        </InstallationButtonWrapper>
        <Typography variant="body1">
          Once, the 2-way syncing with Unito App is successfully installed, you can come back to this page and click
          Authorize below.
        </Typography>
        {instructionFields}
        <AuthButtonsWrapper className="clearfix">
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <PullRightButton>
            <Button variant="secondary" onClick={onSubmit} type="href">
              Authorize
            </Button>
          </PullRightButton>
        </AuthButtonsWrapper>
      </Box>
    </div>
  );
}

AuthMondayInstallationStep1.propTypes = {
  children: PropTypes.node,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
