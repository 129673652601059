import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Icon, tokens } from '@unitoio/mosaic';

const Circle = styled(Box)`
  min-width: ${tokens.spacing.s6};
  max-height: ${tokens.spacing.s6};
  border-radius: 50%;
  margin-right: ${tokens.spacing.s5};
`;

const StyledIcon = styled(Icon)`
  color: ${(props) =>
    props.$error ? tokens.colors.content.destructive.default : tokens.colors.content.positive.default};
`;

const StyledBox = styled(Box)`
  color: ${(props) => props.$error && tokens.colors.content.destructive.default};
`;

export const ModalIframeSection = ({
  circleBackgroundColor,
  circleBorderColor,
  children,
  isStepCompleted,
  stepNumber,
  activationFailed = false,
}) => (
  <Box
    borderSize={1}
    borderRadius={1}
    m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s3, tokens.spacing.s0]}
    p={[tokens.spacing.s4, tokens.spacing.s7, tokens.spacing.s4, tokens.spacing.s4]}
    flexDirection="row"
  >
    <Circle
      justifyContent="center"
      alignItems="center"
      backgroundColor={activationFailed ? tokens.colors.background.message.destructive : circleBackgroundColor}
      borderColor={activationFailed ? tokens.colors.content.destructive.default : circleBorderColor}
    >
      <StyledBox $error={activationFailed}>
        {isStepCompleted ? (
          <StyledIcon $error={activationFailed} kind="solid" name={activationFailed ? 'xmark' : 'check'} />
        ) : (
          stepNumber
        )}
      </StyledBox>
    </Circle>
    {children}
  </Box>
);

ModalIframeSection.propTypes = {
  children: PropTypes.node.isRequired,
  circleBackgroundColor: PropTypes.string.isRequired,
  circleBorderColor: PropTypes.string.isRequired,
  isStepCompleted: PropTypes.bool.isRequired,
  stepNumber: PropTypes.number.isRequired,
  activationFailed: PropTypes.bool,
};
