import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Input, NewButton as Button, Icon, tokens, NewTooltip as Tooltip, NewTypography } from '@unitoio/mosaic';

import { createDraft } from '~/actions/drafts';
import { getLink } from '~/actions/links';
import { useLocalStorage } from '~/hooks';

export const QuickDraft = () => {
  const [selectedLinkId, setSelectedLinkId] = useLocalStorage('quickDraftLinkId');
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleCreateQuickDraft = async () => {
    try {
      setIsLoading(true);
      const { link: originalLink } = await dispatch(getLink(selectedLinkId, {}, { displayError: false }));
      const { link: newlyCreatedDraft } = await dispatch(
        createDraft({
          A: {
            ...originalLink.A,
            providerName: originalLink.A.providerName,
            providerIdentityId: originalLink.A.providerIdentity._id,
            itemType: originalLink.A.itemType,
            containerId: originalLink.A.container.id,
          },
          B: {
            ...originalLink.B,
            providerName: originalLink.B.providerName,
            providerIdentityId: originalLink.B.providerIdentity._id,
            itemType: originalLink.B.itemType,
            containerId: originalLink.B.container.id,
          },
        }),
      );
      history.push(`/dashboard/flow-builder/edit/${newlyCreatedDraft._id}/tool-selection`);
    } catch (error) {
      console.error('looks like something went wrong, what a shame', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <NewTypography.Paragraph style={{ marginBottom: tokens.spacing.s4 }}>
        This module will create a brand new draft but simply pre-fill the workitems to match the ones from the selected
        linkID.
      </NewTypography.Paragraph>
      <Input
        placeholder="link id"
        onChange={(e) => setSelectedLinkId(e.target.value)}
        value={selectedLinkId}
        suffix={
          <Tooltip title="\Your linkId will also be preserved and persist throughout sessions!">
            <Icon name="info-circle" color={tokens.colors.content.info.default} />
          </Tooltip>
        }
      />
      <Button
        loading={isLoading}
        style={{ marginTop: tokens.spacing.s4 }}
        type="primary"
        icon={<Icon name="plus" />}
        onClick={handleCreateQuickDraft}
      >
        Create quick draft
      </Button>
    </>
  );
};
