import React from 'react';
import styled from 'styled-components';
import { useFormContext, Controller } from 'react-hook-form';

import { Box, Icon, Input, NewTypography, tokens, Typography, Space } from '@unitoio/mosaic';

const PreviewLabel = styled(Typography)`
  color: ${tokens.colors.content.neutral.n30};
`;

export const PrefixInputField = ({ label, name, disabled }) => {
  const { control, watch } = useFormContext();
  const prefixValue = watch(name);

  return (
    <Space direction="vertical">
      <NewTypography.Text variant="body1">{label}</NewTypography.Text>
      <Controller control={control} name={name} render={({ field }) => <Input {...field} disabled={disabled} />} />
      <Box flexDirection="row">
        <Box p={[0, tokens.spacing.s2, 0, 0]}>
          <PreviewLabel variant="body1">
            <Icon name="eye" /> Preview:
          </PreviewLabel>
        </Box>
        <Typography variant="body1">{prefixValue}1234 - My title</Typography>
      </Box>
    </Space>
  );
};

PrefixInputField.propTypes = {
  ...Input.propTypes,
};
