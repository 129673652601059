import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';

import { Alert, Box, Button, Icon, ProviderIcon, tokens, Typography } from '@unitoio/mosaic';

import * as containerActions from '~/actions/containers';
import { getContainerById } from '~/reducers';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { useLogger } from '~/hooks/useLogger';
import * as trackingTypes from '~/consts/tracking';

import { useGetProviderExtraStep } from '../../../hooks/useGetProviderExtraStep';
import { useHasMissingExtraStepAction } from '../../../hooks/useHasMissingExtraStepAction';
import { InstructionsModal } from './InstructionsModal';

const ContainerName = styled(Typography)`
  margin-left: ${tokens.spacing.s3};
`;

const InstalledInfo = styled(Box)`
  white-space: nowrap;
  line-height: ${tokens.spacing.s4};
`;

const TypographyWithLineHeight = styled(Typography)`
  line-height: ${tokens.spacing.s4};
`;

const BoxWithCursor = styled(Box)`
  cursor: pointer;
`;

const BoxDisabled = styled(Box)`
  cursor: wait;
`;

function AddOnStatus({ isCheckFailed, containerExtraConfigDetails, onVerifyAddOn }) {
  // NB* the copy here reflects terminology used by Microsoft in their office ecosystem.
  // Contrary to GoogleSheets, MS uses add-in rather than add-on. Please consider this in any refactoring.
  if (isCheckFailed && !!containerExtraConfigDetails) {
    return (
      <Alert level="error" size="sm">
        <TypographyWithLineHeight variant="body2">
          {containerExtraConfigDetails.get('message')}
        </TypographyWithLineHeight>
      </Alert>
    );
  }

  if (!containerExtraConfigDetails) {
    return (
      <Box alignItems="center" p={[tokens.spacing.s3, tokens.spacing.s4]}>
        <Icon name="check" color={tokens.colors.content.positive.default} />
        <Box m={[0, 0, 0, tokens.spacing.s3]}>
          <TypographyWithLineHeight variant="body2">Add-in fully configured</TypographyWithLineHeight>
        </Box>
      </Box>
    );
  }

  return (
    <BoxWithCursor alignItems="center" p={[tokens.spacing.s3, tokens.spacing.s4]}>
      <Box m={[0, 0, 0, tokens.spacing.s3]}>
        <TypographyWithLineHeight variant="body2" onClick={onVerifyAddOn}>
          Verify add-in status
        </TypographyWithLineHeight>
      </Box>
    </BoxWithCursor>
  );
}

AddOnStatus.propTypes = {
  isCheckFailed: PropTypes.bool.isRequired,
  containerExtraConfigDetails: PropTypes.instanceOf(Map),
  onVerifyAddOn: PropTypes.func.isRequired,
};

const Step = ({ containerName, providerIdentityId, containerId, containerType, itemType }) => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const { reportException, reportWarning } = useLogger();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);
  const [isCheckFailed, setIsCheckFailed] = useState(false);
  const selectedContainer = useSelector((state) => getContainerById(state, providerIdentityId, containerId));
  const providerName = 'excel';
  const providerItemAExtraStep = useGetProviderExtraStep(providerName);
  const containerExtraConfigDetails = useHasMissingExtraStepAction(
    providerItemAExtraStep,
    providerName,
    providerIdentityId,
    containerId,
  );

  const reloadContainer = async () => {
    if (providerIdentityId && containerId && containerType) {
      try {
        setIsLoadingCheck(true);
        const { container } = await dispatch(
          containerActions.getContainerById({ providerIdentityId, containerId, containerType, itemType }),
        );

        setIsLoadingCheck(false);
        if (container.size !== 0 && !!containerExtraConfigDetails) {
          setIsCheckFailed(true);
          setTimeout(() => {
            setIsCheckFailed(false);
          }, 4000);
        }
      } catch (error) {
        if (error.code < 500) {
          reportWarning(error.message, {
            identifier: 'errorReloadingContainerAfterAddOnInstall reloadContainer Excel.Step',
          });
        } else {
          reportException(error, {
            identifier: 'errorReloadingContainerAfterAddOnInstall reloadContainer Excel.Step',
          });
        }
      } finally {
        setIsLoadingCheck(false);
      }
    }
  };

  const onVerifyAddOnClick = async () => {
    trackEvent(trackingTypes.ACTION, {
      action_name: 'clicked on verify unito add on',
      selected_tool_name: providerName,
    });
    await reloadContainer();
  };

  const containerDisplayName = selectedContainer.get('displayName');
  const containerUrl = selectedContainer.get('url');

  return (
    <>
      <Box
        p={[tokens.spacing.s4]}
        m={[tokens.spacing.s5, 0, 0, 0]}
        borderRadius={tokens.spacing.s3}
        alignItems="center"
        backgroundColor={tokens.colors.background.neutral.grey}
        justifyContent="space-between"
      >
        <ProviderIcon name={providerName} size="small" />
        <ContainerName>{containerName}</ContainerName>
        <InstalledInfo alignItems="center" m={[0, tokens.spacing.s4, 0, 'auto']}>
          {selectedContainer.isEmpty() || isLoadingCheck ? (
            <BoxDisabled alignItems="center" p={[tokens.spacing.s3, tokens.spacing.s4]}>
              <Box m={[0, 0, 0, tokens.spacing.s3]}>
                <TypographyWithLineHeight variant="body2" color={tokens.colors.content.neutral.n20}>
                  Verifying add-in status...
                </TypographyWithLineHeight>
              </Box>
            </BoxDisabled>
          ) : (
            <AddOnStatus
              isCheckFailed={isCheckFailed}
              containerExtraConfigDetails={containerExtraConfigDetails}
              onVerifyAddOn={onVerifyAddOnClick}
            />
          )}
        </InstalledInfo>

        <Button
          size={Button.sizes.SM}
          onClick={() => {
            setIsModalOpen(true);
            trackEvent(trackingTypes.ACTION, {
              action_name: 'clicked on install unito add on',
              selected_tool_name: providerName,
            });
          }}
        >
          Install Unito add-in
        </Button>
      </Box>
      {containerDisplayName && containerUrl && (
        <InstructionsModal
          isOpen={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onConfirm={async () => {
            setIsModalOpen(false);
            await reloadContainer();
          }}
          containerName={containerDisplayName}
          containerUrl={containerUrl}
        />
      )}
    </>
  );
};

Step.propTypes = {
  containerName: PropTypes.string.isRequired,
  providerIdentityId: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired,
  containerType: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
};

export { Step as ExcelStep };
