import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useWatch, useFormContext } from 'react-hook-form';

import { Box, Icon, Space, tokens } from '@unitoio/mosaic';
import * as fieldTypes from '~/consts/fields';
import { otherSide } from '~/utils/otherSide';

import { FieldValuesSelect } from '../FieldValuesSelect';
import { PrefixInputField } from './PrefixInputField';

const InputAction = styled(Box)`
  cursor: pointer;
`;

export const FieldMappingPrefixRow = ({
  field,
  index,
  keySelected = false,
  remove,
  side,
  fieldId,
  selectedPrefixes,
  placeholder,
}) => {
  const { register } = useFormContext();
  // We fetch the values from the other side to select the task type of the other provider
  const containerId = useWatch({ name: `${otherSide(side)}.containerId` });
  const providerIdentityId = useWatch({ name: `${otherSide(side)}.providerIdentityId` });
  const itemType = useWatch({ name: `${otherSide(side)}.itemType` });
  const containerType = useWatch({ name: `${otherSide(side)}.containerType` });

  return (
    <Space>
      <Box fullWidth>
        <FieldValuesSelect
          kind={fieldTypes.KINDS.PCD_TYPED_FIELD}
          fieldId={fieldId}
          providerIdentityId={providerIdentityId}
          containerId={containerId}
          containerSide={otherSide(side)}
          placeholder={placeholder}
          isOptionDisabled={({ id }) => ({ disabled: selectedPrefixes.includes(id) })}
          size="md"
          itemType={itemType}
          containerType={containerType}
          name={`${side}.prefixes.${index}.key`}
          {...register(`${side}.prefixes.${index}.key`)}
        />
      </Box>
      <Box fullWidth p={[0, 0, 0, tokens.spacing.s4]}>
        <PrefixInputField
          disabled={!keySelected}
          id={`value-prefix-${index}`}
          name={`${side}.prefixes.${index}.value`}
        />
      </Box>
      <Box p={[tokens.spacing.s2]}>
        <InputAction
          p={[tokens.spacing.s3]}
          flexDirection="row"
          role="button"
          onClick={() => {
            remove(index);
          }}
        >
          <Icon name="trash" kind={Icon.KINDS.SOLID} title="remove" />
        </InputAction>
      </Box>
    </Space>
  );
};
FieldMappingPrefixRow.propTypes = {
  index: PropTypes.number.isRequired,
  keySelected: PropTypes.bool,
  fieldId: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onPrefixChange: PropTypes.func.isRequired,
  side: PropTypes.oneOf(['A', 'B']).isRequired,
  field: PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  remove: PropTypes.func.isRequired,
  selectedPrefixes: PropTypes.arrayOf(PropTypes.string).isRequired,
};
