import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Flex, NewTypography, Space, SidePanel } from '@unitoio/mosaic';

import { TrackingFunnel } from '~/containers/TrackingFunnel/TrackingFunnel';
import { getLinkById } from '~/reducers';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import * as trackingTypes from '~/consts/tracking';

import { ResourceBlock } from '../../components/ResourceBlock';
import { SidePanelVideoIframe } from './SidePanelVideoIframe';

import Mapping1 from '../../images/side_panel/mapping/mapping1.svg';
import Mapping2 from '../../images/side_panel/mapping/mapping2.svg';
import Mapping3 from '../../images/side_panel/mapping/mapping3.svg';
import Mapping4 from '../../images/side_panel/mapping/mapping4.svg';
import Mapping5 from '../../images/side_panel/mapping/mapping5.svg';
import Mapping6 from '../../images/side_panel/mapping/mapping6.svg';
import Mapping7 from '../../images/side_panel/mapping/mapping7.svg';

const MappingSidePanel = ({ isOpen, onClose, linkId }) => {
  const link = useSelector((state) => getLinkById(state, linkId));
  const trackEvent = useTrackEvent({
    selected_tool_names: `${link.getIn(['A', 'providerName'])},${link.getIn(['B', 'providerName'])}`,
  });

  useEffect(() => {
    if (isOpen) {
      trackEvent(trackingTypes.START);
    }
  }, [isOpen, trackEvent]);

  return (
    <SidePanel onClose={onClose} isOpen={isOpen} backButtonText="Close">
      <Flex vertical>
        <Space direction="vertical" size="middle">
          <NewTypography.Title level={4}>Need some help mapping your fields?</NewTypography.Title>
          <SidePanelVideoIframe videoId="MAqjcem5NkM" title="Flow Creation Step 4/4 - Mappings" />
          <NewTypography.Text strong>
            In the previous step, you chose which items to sync from one tool to another. Now you can choose which
            individual fields within your work items you'd like to keep in sync.
          </NewTypography.Text>
          <NewTypography.Text strong>
            You can choose whether to have Unito auto-map fields automatically, or to do it manually yourself.
          </NewTypography.Text>
          <Space>
            <img alt="auto or manual mapped field" src={Mapping1} width="100%" height="100%" />
          </Space>
          <NewTypography.Text strong>
            For example, if you have a task in one tool and you only care about the title and description, you don’t
            need to add any other fields to your flow. Or you can add as many as you can think of. It all depends on
            your use case and preferences.
          </NewTypography.Text>
          <NewTypography.Text strong>
            You can add a new field mapping by clicking the button at the top.
          </NewTypography.Text>
          <Space>
            <img alt="add new mapping" src={Mapping2} width="100%" height="100%" />
          </Space>
          <NewTypography.Text strong>
            Your new mapping will then appear at the top of the list. Typically you’ll want to pair it with the same or
            a similar field in your other tool.
          </NewTypography.Text>
          <Space>
            <img alt="mapped similar field" src={Mapping3} width="100%" height="100%" />
          </Space>
          <NewTypography.Text strong>
            If there is no obvious or compatible match for a field in another tool, our users often select the
            description footer so the data has somewhere to go that can still be accessed by a team using that other
            tool.
          </NewTypography.Text>
          <Space>
            <img alt="mapping direction" src={Mapping4} width="100%" height="100%" />
          </Space>
          <NewTypography.Text strong>
            If you see a cog, or gear icon, beside a field mapping you can click on it to view the options associated
            with that mapping.
          </NewTypography.Text>
          <Space>
            <img alt="mapping option with gear icon" src={Mapping5} width="100%" height="100%" />
          </Space>
          <NewTypography.Text strong>
            In the case of labels, tags, or lists, you can add or modify values to match different names between tools.
            For example, you could tell Unito that the “in progress” list in Tool A maps to the “active” list in Tool B.
          </NewTypography.Text>
          <NewTypography.Text strong>
            You can map one-to-one, or one-to-many, giving you lots of flexibility. Perhaps there are a variety of
            statuses in one tool that all equal a single status in another. For example, “To Do” and “In Progress” in
            Tool A might all equal “Incomplete” in Tool B.
          </NewTypography.Text>
          <Space>
            <img alt="one-to-one and one-to-many mapping" src={Mapping6} width="100%" height="100%" />
          </Space>
          <NewTypography.Text strong>
            It may not be possible to map some fields with others. If that’s the case, you can submit a support request
            to find a workaround from our team or ask about enhancement.
          </NewTypography.Text>
          <NewTypography.Text strong>
            If you decide to add a new field to your tool while editing mappings, you’ll need to refresh the page in
            order for Unito to detect it.
          </NewTypography.Text>
          <NewTypography.Text strong>
            Sub-items, such as subtasks and checklists, will follow your flow direction’s behavior at all times and also
            count as items in sync.
          </NewTypography.Text>
          <NewTypography.Title level={4}>Common error messages</NewTypography.Title>
          <NewTypography.Title level={5}>This field is limited</NewTypography.Title>
          <Space>
            <img alt="mapped similar field" src={Mapping7} width="100%" height="100%" />
          </Space>
          <NewTypography.Text strong>
            This typically happens because of a limitation on how many fields of a certain type can be mapped in the
            same flow.
          </NewTypography.Text>
          <NewTypography.Text strong>
            Some tools might have multiple status-type fields, like Issue Status and Sprint. Only two instances of this
            field type can be mapped in a single flow, so if you mapped Issue Status to two fields in another tool, you
            couldn’t also map the Sprint field in the same flow. This would give you the “limited” error.
          </NewTypography.Text>
          <ResourceBlock trackEvent={trackEvent} />
        </Space>
      </Flex>
    </SidePanel>
  );
};

MappingSidePanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  linkId: PropTypes.string.isRequired,
};

const MappingSidePanelWithTracking = (props) => (
  <TrackingFunnel contextName={trackingTypes.FUNNELS.SIDE_PANEL}>
    <MappingSidePanel {...props} />
  </TrackingFunnel>
);

export { MappingSidePanelWithTracking as MappingSidePanel };
