import React from 'react';
import PropTypes from 'prop-types';

import { useQueryParams } from '~/hooks/useQueryParams';
import { Button } from '~/components/Button/Button';
import { Section } from '~/components/Section/Section';
import { Title } from '~/components/Title/Title';
import { CopyToClipboard } from '~/components/CopyToClipboard/CopyToClipboard';

import { AuthButtonsWrapper } from '../../../AuthButtonsWrapper';
import { AuthTitleWrapper } from '../../../AuthTitleWrapper';
import { AuthOrderedList } from '../../AuthOrderedList';
import { AuthImageWrapper } from '../../AuthImageWrapper';
import stepImage from './jira-oauth2-3.png';

function getAuthCallbackURL(providerName) {
  if (['localhost', 'development'].includes(import.meta.env.VITE_ENV)) {
    return `http://localhost:3000/api/auth/${providerName}/callback`;
  }

  if (import.meta.env.VITE_ENV === 'staging') {
    return `https://staging-app.unito.io/api/auth/${providerName}/callback`;
  }

  return `https://app.unito.io/api/auth/${providerName}/callback`;
}

export function AuthJiraOAuth2Step3({ history, onCancel, trackEvent, providerName }) {
  const { windowOpenerId } = useQueryParams();

  return (
    <div className="auth-instruction-step">
      <AuthTitleWrapper>
        <Title type="h2" align="center">
          (OAuth2 - Step 3 of 4)
        </Title>
      </AuthTitleWrapper>
      <Section>
        <AuthImageWrapper className="clearfix">
          <img src={stepImage} alt="Jira OAuth2 Step - Step 3" />
        </AuthImageWrapper>
        <p>In the dialog that appears, input the following:</p>
        <AuthOrderedList>
          <li>
            Name: <CopyToClipboard>Unito Sync</CopyToClipboard>
          </li>
          <li>
            Redirect URL: <CopyToClipboard>{getAuthCallbackURL(providerName)}</CopyToClipboard>
          </li>
          <li>
            Permission: <CopyToClipboard>Write</CopyToClipboard>
          </li>
        </AuthOrderedList>
        <AuthButtonsWrapper className="clearfix">
          <Button
            btnStyle="dark"
            to={`step4?windowOpenerId=${windowOpenerId}`}
            onClick={() => trackEvent('oauth2 onboarding 3 (of 4)')}
            type="href"
            pullRight
          >
            Continue
          </Button>
          <Button btnStyle="link" onClick={() => history.goBack()} pullRight>
            Back
          </Button>
          <Button reverse btnStyle="dark" onClick={onCancel}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </Section>
    </div>
  );
}

AuthJiraOAuth2Step3.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  onCancel: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  providerName: PropTypes.string.isRequired,
};
