import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, ProviderIcon, tokens, Typography, TypographyVariants } from '@unitoio/mosaic';

import { capitalize } from '~/utils/capitalize';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';

const StretchedBox = styled(Box)`
  width: 100%;
  gap: ${tokens.spacing.s3};
`;

export const ThenStatementRuleRow = ({ otherContainerSection, prefix }) => (
  <StretchedBox
    p={[tokens.spacing.s3, tokens.spacing.s4, tokens.spacing.s3, 0]}
    flexDirection="row"
    alignItems="flex-start"
  >
    <Box m={[0, tokens.spacing.s2, 0, 0]}>
      {prefix || (
        <Box p={[0, 0, 0, tokens.spacing.s4]}>
          <Typography variant="h4">THEN</Typography>
        </Box>
      )}
    </Box>
    <Typography variant={TypographyVariants.BODY2}>
      <Box
        backgroundColor={tokens.colors.background.neutral.grey}
        p={[tokens.spacing.s2, tokens.spacing.s3]}
        borderRadius={0.4}
      >
        Create a matching{' '}
        <ProviderTermsByName
          providerNameA={otherContainerSection.providerName}
          containerTypeA={otherContainerSection.containerType}
          itemTypeA={otherContainerSection.itemType}
          containerIdA={otherContainerSection.containerId}
          termKey="task"
          pcdv3
        />{' '}
        in
      </Box>
    </Typography>
    <Typography variant={TypographyVariants.BODY2}>
      <Box
        backgroundColor={tokens.colors.background.neutral.grey}
        p={[tokens.spacing.s2, tokens.spacing.s3]}
        borderRadius={0.4}
        flexDirection="row"
      >
        <Box m={[0, tokens.spacing.s3, 0, 0]}>
          <ProviderIcon name={otherContainerSection.providerName} size="small" />
        </Box>
        {capitalize(otherContainerSection.providerDisplayName)}
      </Box>
    </Typography>
    <Typography variant={TypographyVariants.BODY2}>
      <Box
        backgroundColor={tokens.colors.background.neutral.grey}
        p={[tokens.spacing.s2, tokens.spacing.s3]}
        borderRadius={0.4}
      >
        {otherContainerSection.containerName}
      </Box>
    </Typography>
  </StretchedBox>
);

ThenStatementRuleRow.propTypes = {
  otherContainerSection: PropTypes.shape({
    providerName: PropTypes.string.isRequired,
    providerDisplayName: PropTypes.string.isRequired,
    containerName: PropTypes.string.isRequired,
    containerType: PropTypes.string.isRequired,
    containerId: PropTypes.string.isRequired,
    itemType: PropTypes.string.isRequired,
  }).isRequired,
  prefix: PropTypes.node,
};
