import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { NewModal, NewTypography, Space, Spinner, tokens, Box, LoadingPlaceholder } from '@unitoio/mosaic';

const translateText = keyframes`
  // This simply translates the text up and down every 20% of the total animation length,
  // and it translates at a velocity of 5% of the animation length
  0%, 20%, 100% {transform:translate3d(0,0,0);}
  25%, 45% {transform:translate3d(0,-25%,0);}
  50%,70% {transform:translate3d(0,-50%,0);}
  75%, 95% {transform:translate3d(0,-75%,0);}
`;

const LoadingSentencesContainer = styled.div`
  list-style: none;
  overflow: hidden;
  // height in px for consistency, it needs to be a precise value anyway
  height: 20px;
`;
const LoadingSentencesList = styled.ul`
  animation: ${translateText} 8s infinite;
  list-style: none;
  padding: 0;
  margin: 0;
`;
const LoadingSentencesListItem = styled.li`
  // height in px for consistency, it needs to be a precise value anyway
  height: 20px;
`;
export function MappedFieldsLoading({ isGeneratingFieldAssociations }) {
  const sentences = [
    'Looking for similar fields name',
    'Checking fields compatibility',
    'Mapping fields',
    'Mapping values',
  ];

  if (isGeneratingFieldAssociations) {
    return (
      <NewModal
        closable={false}
        open
        footer={[]}
        style={{
          textAlign: 'center',
        }}
      >
        <Space direction="vertical" size="middle" align="center" block>
          <NewTypography.Title level={4}>Mapping fields automatically...</NewTypography.Title>
          <LoadingSentencesContainer>
            <LoadingSentencesList>
              {sentences.map((sentence) => (
                <LoadingSentencesListItem key={sentence}>
                  <NewTypography.Text style={{ color: tokens.colors.content.neutral.n30 }} align="center">
                    {sentence}
                  </NewTypography.Text>
                </LoadingSentencesListItem>
              ))}
            </LoadingSentencesList>
          </LoadingSentencesContainer>
          <Spinner />
        </Space>
      </NewModal>
    );
  }
  return (
    <Box m={[tokens.spacing.s4, 0, 0, 0]}>
      <LoadingPlaceholder width="100%" height="2rem" />
    </Box>
  );
}

MappedFieldsLoading.propTypes = {
  isGeneratingFieldAssociations: PropTypes.bool.isRequired,
};
