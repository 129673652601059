import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, HorizontalSeparator, tokens } from '@unitoio/mosaic';

import { StepDot } from './StepDot';

const FirstStepContainer = styled(Box)`
  flex: 1;
`;

const StepsContainer = styled(Box)`
  width: 10vw;
  margin: 0 auto;
`;

export const Steps = ({ hasErrors = false, activeStep, totalStepCircles }) => {
  const horizontalLineColor = hasErrors
    ? tokens.colors.content.destructive.default
    : tokens.colors.content.branding.unito;

  return (
    <StepsContainer flexDirection="row" justifyContent="center">
      <FirstStepContainer alignItems="center" justifyContent="center" p={[tokens.spacing.s4, 0, tokens.spacing.s4, 0]}>
        {Array.from(Array(totalStepCircles), (_step, i) => {
          if (i < totalStepCircles - 1) {
            return (
              <Fragment key={i}>
                <StepDot
                  shouldColorDot={activeStep >= i + 1}
                  stepId={i + 1}
                  activeStep={activeStep}
                  hasErrors={hasErrors}
                />
                <HorizontalSeparator color={horizontalLineColor} />
                <HorizontalSeparator
                  color={activeStep >= totalStepCircles ? horizontalLineColor : tokens.colors.content.neutral.n10}
                />
              </Fragment>
            );
          }
          return <StepDot key={i} shouldColorDot={activeStep >= i + 1} stepId={i + 1} activeStep={activeStep} />;
        })}
      </FirstStepContainer>
    </StepsContainer>
  );
};

Steps.propTypes = {
  activeStep: PropTypes.number.isRequired,
  totalStepCircles: PropTypes.number.isRequired,
  hasErrors: PropTypes.bool,
};
