import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { Box, Button, Grid, Icon, tokens } from '@unitoio/mosaic';

import * as linkTypes from '~/consts/link';
import { UnitoLogo } from '~/components/UnitoLogo/UnitoLogo';
import * as linkActions from '~/actions/links';
import { RightHeaderContent } from '../../../HeaderContainer/RightHeaderContent';
import { FlowActionsNavbar } from './FlowActionsNavbar';
import { useGetBackNavigationDetails } from './hooks/useGetBackNavigationDetails';
import { useIsFlowDuplicate } from '../../hooks/useIsFlowDuplicate';

const Navbar = styled((props) => <Grid {...props} />)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${tokens.colors.global.primary.light};
  border-bottom: 1px solid ${tokens.colors.content.neutral.n10};
  padding-right: ${tokens.spacing.s4};
  position: sticky;
  top: 0;
  z-index: 1;
  min-height: 70px;
`;

// min-height value doesn't fall under any mimics token
// but design agrees that this is the value to use.

const LinkName = styled.div`
  font-size: ${tokens.fontSize.f5};
  &:hover {
    cursor: pointer;
  }
`;

const ReadOnlyName = styled.span`
  display: inline-flex;
  flex-flow: row nowrap;
`;

export function FlowBuilderHeader({ disableSave = false }) {
  const history = useHistory();
  const { watch, setValue } = useFormContext();
  const { linkId } = useParams();
  const [isEditingName, setEditingName] = useState(false);
  const dispatch = useDispatch();
  const isDraftFlow = linkId && [linkTypes.LINK_STATES.DRAFT, linkTypes.LINK_STATES.DUPLICATE].includes(watch('state'));
  // we still individually watch these fields for rendering purposes
  const watchName = watch('name');
  const [flowName, setFlowName] = useState(watchName);

  const { url: goBackUrl, copy: goBackCopy } = useGetBackNavigationDetails();

  const handleChange = (event) => {
    setFlowName(event.target.value);
  };

  const handleEditLinkName = () => {
    if (!flowName) {
      setFlowName(watchName);
    } else {
      setValue('name', flowName, { shouldDirty: false });
      dispatch(linkActions.editLinkFields(linkId, { name: flowName }));
    }
  };

  const handleOnKeyDown = (event) => {
    // 13: Enter key
    // 27: ESC key
    if (event.keyCode === 13) {
      handleEditLinkName(flowName);
    }
    if ([13, 27].includes(event.keyCode)) {
      setEditingName(false);
    }
  };

  const isDuplicatingFlow = useIsFlowDuplicate();
  const isActiveLink = !isDraftFlow && linkId && !isDuplicatingFlow;

  // Block 'Back to ...' navigation in case the user is creating/editing their first draft
  // show the Unito logo instead.
  const shouldShowLogo = !goBackUrl;

  return (
    <Navbar fluid="true" as="header">
      <div>
        {shouldShowLogo ? (
          <Box m={[0, tokens.spacing.s4]} className="pull-left">
            <UnitoLogo kind="horizontal" color="color" width="132px" />
          </Box>
        ) : (
          <Box m={[tokens.spacing.s2, 0, 0, tokens.spacing.s3]}>
            <Button
              variant={Button.variants.SUBTLE}
              startIcon="chevron-left"
              iconKind={Icon.KINDS.SOLID}
              data-dd-action-name={goBackCopy}
              onClick={() => history.push(goBackUrl)}
            >
              {goBackCopy}
            </Button>
          </Box>
        )}
      </div>
      {isActiveLink && (
        <div>
          <LinkName>
            {isEditingName ? (
              <input
                autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                className="form-control"
                maxLength="80"
                name="name"
                onKeyDown={(event) => handleOnKeyDown(event)}
                onChange={(event) => handleChange(event)}
                onBlur={handleEditLinkName}
                size="80"
                type="text"
                value={flowName}
              />
            ) : (
              <Box m={[0, 1, 0, 0]} onClick={() => setEditingName(true)}>
                <ReadOnlyName>
                  {watchName}
                  <Box m={[0, 0, 0, 0.5]}>
                    <Icon name="pencil" aria-label="Edit flow name" />
                  </Box>
                </ReadOnlyName>
              </Box>
            )}
          </LinkName>
        </div>
      )}
      <div style={{ paddingleft: 'auto' }}>
        {isActiveLink ? <FlowActionsNavbar disableSave={disableSave} linkId={linkId} /> : <RightHeaderContent />}
      </div>
    </Navbar>
  );
}

FlowBuilderHeader.propTypes = {
  disableSave: PropTypes.bool,
};
