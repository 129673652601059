import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, SidePanel, Typography, tokens } from '@unitoio/mosaic';

import { TrackingFunnel } from '~/containers/TrackingFunnel/TrackingFunnel';
import { getLinkById } from '~/reducers';
import * as trackingTypes from '~/consts/tracking';
import { useTrackEvent } from '~/hooks/useTrackEvent';

import { ResourceBlock } from '../../components/ResourceBlock';

import FlowDirection2 from '../../images/side_panel/flow_direction/flow_direction2.svg';
import FlowDirection1 from '../../images/side_panel/flow_direction/flow_direction1.svg';
import { SidePanelVideoIframe } from './SidePanelVideoIframe';

const FlowDirectionSidePanel = ({ onClose, isOpen, linkId }) => {
  const link = useSelector((state) => getLinkById(state, linkId));
  const trackEvent = useTrackEvent({
    selected_tool_names: `${link.getIn(['A', 'providerName'])},${link.getIn(['B', 'providerName'])}`,
  });

  useEffect(() => {
    if (isOpen) {
      trackEvent(trackingTypes.START);
    }
  }, [isOpen, trackEvent]);

  return (
    <SidePanel onClose={onClose} isOpen={isOpen} backButtonText="Close">
      <Box>
        <Box m={[0, 0, tokens.spacing.s4, 0]}>
          <Typography variant="h4">Need some help deciding on your flow direction?</Typography>
        </Box>
        <Box m={[0, 0, tokens.spacing.s4, 0]}>
          <SidePanelVideoIframe videoId="hN9lMCJLVh0" title="Flow Creation Step 2/4 - Flow Direction" />
        </Box>
        <Box alignItems="center" flexDirection="column" m={[0, 0, tokens.spacing.s7, 0]}>
          <Box m={[0, 0, tokens.spacing.s3, 0]}>
            <Box m={[0, 0, tokens.spacing.s4, 0]}>
              <Typography variant="body1">
                Flow direction determines which of your connected tools will create new work items and sub-items, such
                as checklists, in your other tools.
              </Typography>
            </Box>
            <Box m={[0, 0, tokens.spacing.s4, 0]}>
              <Typography variant="body1">
                Choose a one-way flow to have work items from Tool A appear in Tool B, but not the other way around.
              </Typography>
            </Box>

            <Box m={[0, 0, tokens.spacing.s4, 0]}>
              <Typography variant="body1">
                Choose a two-way flow if you want work items in either Tool A or Tool B to appear in the other.
              </Typography>
            </Box>

            <Box m={[0, 0, tokens.spacing.s4, 0]}>
              <Typography variant="body1">
                Regardless of which flow direction you choose here, later you'll be able to choose which fields are
                updated 1-way or 2-way.
              </Typography>
            </Box>

            <Box m={[0, 0, tokens.spacing.s4, 0]}>
              <Typography variant="body1">Here's a quick example of flow direction.</Typography>
            </Box>

            <Box m={[0, 0, tokens.spacing.s4, 0]}>
              <Typography variant="body1">
                If you only want your Jira issues to appear as Asana tasks, choose a one-way flow from Jira to Asana.
              </Typography>
            </Box>
          </Box>
          <Box m={[0, 0, tokens.spacing.s3, 0]}>
            <img alt="one-way flow" src={FlowDirection1} width="100%" height="100%" />
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant="body1">
              However, if you also want to be able to create Asana subtasks and send them to Jira, you’ll need a two-way
              flow.
            </Typography>
          </Box>
          <Box>
            <img alt="two-way flow" src={FlowDirection2} width="100%" height="100%" />
          </Box>
          <Box fullWidth>
            <Box m={[0, 0, tokens.spacing.s4, 0]}>
              <Typography variant="body1">
                Overall, two-way flows are more powerful, allow for more flexibility, and true collaboration.
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">You can always change this setting later.</Typography>
            </Box>
          </Box>
        </Box>
        <Box fullWidth>
          <ResourceBlock trackEvent={trackEvent} />
        </Box>
      </Box>
    </SidePanel>
  );
};

FlowDirectionSidePanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  linkId: PropTypes.string.isRequired,
};

const FlowDirectionSidePanelWithTracking = (props) => (
  <TrackingFunnel contextName={trackingTypes.FUNNELS.SIDE_PANEL}>
    <FlowDirectionSidePanel {...props} />
  </TrackingFunnel>
);

export { FlowDirectionSidePanelWithTracking as FlowDirectionSidePanel };
