import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Bold, Box, Link, Modal, tokens, Typography } from '@unitoio/mosaic';

import { useTrackEvent } from '~/hooks/useTrackEvent';
import * as trackingTypes from '~/consts/tracking';

import { GOOGLE_ADD_ON } from '../../../consts/flowBuilder';
import AddOnUnito from './AddOnUnito.mp4';
import AddOnUnitoThumbnail from './AddOnUnitoThumbnail.png';

const BoxWithGap = styled(Box)`
  gap: ${tokens.spacing.s3};
`;

const VideoWithBorder = styled.video`
  border: 1px solid ${tokens.colors.content.neutral.n40};
  border-bottom: none;
`;

const marketplaceLink =
  import.meta.env.VITE_ENV === 'staging' ? GOOGLE_ADD_ON.STAGING_LINK : GOOGLE_ADD_ON.PRODUCTION_LINK;

export const InstructionsModal = ({ isOpen, onCancel, onConfirm, containerUrl }) => {
  const trackEvent = useTrackEvent();

  const trackOnOpenSheet = () => {
    trackEvent(trackingTypes.ACTION, {
      action_name: 'clicked on spreadsheet link in add on instructions',
      selected_tool_name: 'googlesheets',
    });
  };

  return (
    <Modal
      size="lg"
      confirmLabel="Confirm installation"
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      onRequestClose={onConfirm}
      title="To install and activate Unito, you must:"
      useNewButton
      displayCloseButton
    >
      <BoxWithGap
        p={[tokens.spacing.s4]}
        m={[tokens.spacing.s4, 0, tokens.spacing.s4, 0]}
        borderStyle="solid"
        borderRadius={tokens.spacing.s4}
        justifyContent="space-between"
        flexDirection="column"
      >
        <Bold>A. Install the add-on in your Google workspace</Bold>
        <Typography>
          Go to
          <Link href={marketplaceLink} isExternalLink>
            Google marketplace
          </Link>{' '}
          and Install
        </Typography>
      </BoxWithGap>
      <Box
        p={[tokens.spacing.s4]}
        m={[tokens.spacing.s4, 0, tokens.spacing.s4, 0]}
        borderStyle="solid"
        borderRadius={tokens.spacing.s4}
        justifyContent="space-between"
        flexDirection="column"
      >
        <Bold>B. Activate Unito Spreadsheet Sync in your sheet</Bold>
        <ul>
          <li>
            Go to
            <Link href={containerUrl} onClick={trackOnOpenSheet} isExternalLink>
              your sheet
            </Link>{' '}
          </li>
          <li>Click 'Extensions'</li>
          <li>
            Choose 'Unito Spreadsheet Sync', then ‘Open Unito’, and follow on-screen instructions to activate the
            add-on.
          </li>
        </ul>
        <VideoWithBorder src={AddOnUnito} width="100%" controls poster={AddOnUnitoThumbnail} />
      </Box>
    </Modal>
  );
};
InstructionsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  containerUrl: PropTypes.string.isRequired,
};
