import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, tokens } from '@unitoio/mosaic';

export const AUTOPOPULATE_ALT_TEXT = 'gif representing the auto-populate feature';

const AutopopulateBox = styled(Box)`
  max-width: 55%;
`;

export function AutopopulateContentLayout({ children, title, image }) {
  return (
    <AutopopulateBox m={['auto']}>
      {title}
      <Box
        borderStyle="solid"
        borderRadius={tokens.spacing.s3}
        fullWidth
        flexDirection="column"
        justifyContent="space-between"
        p={[tokens.spacing.s6]}
        m={[tokens.spacing.s4, 0, 0, 0]}
      >
        {image}
        <Box flexDirection="column" alignItems="center" m={[tokens.spacing.s6, 0, 0, 0]}>
          {children}
        </Box>
      </Box>
    </AutopopulateBox>
  );
}

AutopopulateContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  image: PropTypes.node.isRequired,
};
