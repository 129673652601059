/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Icon, Typography, tokens } from '@unitoio/mosaic';

import { color } from 'theme';
import { InlineLoading } from '~/components/InlineLoading/InlineLoading';

const Table = styled.table`
  width: 100%;
  ${(props) => props.$fixedTableWidth && 'table-layout: fixed;'}

  tr {
    height: 4.5rem;
  }

  th {
    color: ${color.content.neutral.placeholder};
    padding-top: 1.4375rem;
    padding-bottom: 0.75rem;
    font-weight: ${tokens.fontWeight.fw4};
  }

  th,
  td {
    padding-left: 1.1875rem;
    padding-right: 1.1875rem;
  }

  .headers-row th {
    border-bottom: 0px none ${color.content.neutral.white};
    padding-bottom: 0px;
  }

  thead tr:last-child th {
    padding-bottom: 1rem;
    border-bottom: ${({ rows }) => (rows.length ? '0px' : `1px solid ${color.dark.dark5}`)};
  }

  td {
    border-bottom: 1px solid ${color.dark.dark5};
  }

  .filters-row,
  .headers-row {
    height: unset;
  }

  tbody tr:hover {
    background-color: ${color.background.global.action};
    transition: background-color 100ms;
  }
`;

const EmptyTableState = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const getLoading = (isSearchingByURL) => {
  if (isSearchingByURL) {
    return (
      <Box m={[tokens.spacing.s4, 0]}>
        <Typography variant="body2" align="center">
          <Icon name="rotate" kind={Icon.KINDS.SOLID} key="rotate" /> Looking for items that match your ID or URL...
        </Typography>
      </Box>
    );
  }

  return <InlineLoading />;
};

export function ActivityLogsDataTable({
  dataTableData,
  emptyStateComponent = null,
  fixedTableWidth = false, // This makes the header spacing even. Used in the activity log table in flow builder edit
  hasData,
  isLoading,
  isSearchingByURL = false,
}) {
  const {
    // tableInstance should be a table instance created by react-table.
    tableInstance: { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, columns },
    // renderHeaderSort is a Function that renders the sort state, to append next to the header. Could be an upwards or a downwards arrow for example
    renderHeaderSort = () => null,
  } = dataTableData;

  const hasFilters = columns.some((column) => !column.disableFilters);
  const { key: tableKey, ...tableProps } = getTableProps();
  return (
    <Fragment>
      <Table rows={page} $fixedTableWidth={fixedTableWidth} {...tableProps} key={tableKey}>
        <thead>
          {headerGroups.map((headerGroup, i) => {
            const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
            return (
              <Fragment key={headerGroupKey}>
                <tr className="headers-row" {...headerGroupProps}>
                  {headerGroup.headers.map((column) => {
                    const { key: columnKey, ...columnProps } = column.getHeaderProps();
                    return (
                      <th {...columnProps} key={columnKey}>
                        <div {...column.getSortByToggleProps()}>
                          <span>{column.render('header')}</span>
                          <span>{renderHeaderSort(column)}</span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
                {hasFilters && (
                  <tr className="filters-row">
                    {headerGroup.headers.map((column) => {
                      const { key: columnKey, ...columnProps } = column.getHeaderProps();
                      return (
                        <th {...columnProps} key={columnKey}>
                          {column.canFilter ? <Box m={[0.5, 0, 0, 0]}>{column.render('Filter')}</Box> : null}
                        </th>
                      );
                    })}
                  </tr>
                )}
              </Fragment>
            );
          })}
        </thead>

        <tbody {...getTableBodyProps()}>
          {!isLoading &&
            page.map((row) => {
              prepareRow(row);
              const { key: rowKey, ...rowProps } = row.getRowProps();
              return (
                <tr {...rowProps} key={rowKey}>
                  {row.cells.map((cell) => {
                    const { key: cellKey, ...cellProps } = cell.getCellProps();
                    return (
                      <td {...cellProps} key={cellKey}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </Table>
      {!hasData && !isLoading && <EmptyTableState m={[1.5, 0]}>{emptyStateComponent}</EmptyTableState>}
      {isLoading && getLoading(isSearchingByURL)}
    </Fragment>
  );
}

ActivityLogsDataTable.propTypes = {
  dataTableData: PropTypes.shape({
    renderHeaderSort: PropTypes.func,
    // React-Table doesn't export its prop types.
    // eslint-disable-next-line react/forbid-prop-types
    tableInstance: PropTypes.object.isRequired,
  }).isRequired,
  hasData: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  emptyStateComponent: PropTypes.node,
  isSearchingByURL: PropTypes.bool,
  fixedTableWidth: PropTypes.bool,
};
