import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Typography, message, tokens } from '@unitoio/mosaic';

import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { getFirstActivityLogsByItemId } from '~/reducers';
import { ChatBubble } from '~/components/ChatBubble/ChatBubble';

const getContactMessage = (flowName, linkId, itemId, itemURL, syncId) =>
  `I'm looking for some information for the following item:

Flow: ${flowName}
Flow URL: https://${window.location.hostname}/#${routes.getEditFlowBuilderRoute(linkId)}
Flow ID: ${linkId}
Item ID: ${syncId}
${itemURL && `Item URL: ${itemURL}\n`}Item unique ID: ${itemId}
`;

export const ContactSupportBlock = ({ itemId }) => {
  const firstActivityLog = useSelector((state) => getFirstActivityLogsByItemId(state, itemId));
  const flowName = firstActivityLog.getIn(['link', 'name']);
  const linkId = firstActivityLog.get('linkId');
  const syncId = firstActivityLog.get('syncId');
  const itemURL = firstActivityLog.getIn(['targetTask', 'url']);
  const trackEvent = useTrackEvent();

  const handleCopieToClipboard = () => {
    navigator.clipboard.writeText(getContactMessage(flowName, linkId, itemId, itemURL, syncId));
    message.success({
      content: 'Copied to clipboard',
    });
  };

  return (
    <>
      <Box m={[tokens.spacing.s4, 0, tokens.spacing.s4, 0]}>
        <Typography variant="h4">Get more help with this item</Typography>
      </Box>
      <Box
        borderRadius={tokens.spacing.s4}
        borderSize={1}
        borderColor={tokens.colors.content.neutral.n10}
        p={[tokens.spacing.s4]}
        flexDirection="row"
      >
        <Box>
          <Box>
            <Typography variant="body1" color={tokens.colors.content.neutral.n30}>
              I'm looking for some information for the following item:
            </Typography>
            <br />
          </Box>
          <Box>
            <Typography variant="body1" color={tokens.colors.content.neutral.n30}>
              Flow: {flowName}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" color={tokens.colors.content.neutral.n30}>
              Flow URL: {`https://${window.location.hostname}/#${routes.getEditFlowBuilderRoute(linkId)}`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" color={tokens.colors.content.neutral.n30}>
              Flow ID: {linkId}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" color={tokens.colors.content.neutral.n30}>
              Item ID: {syncId}
            </Typography>
          </Box>
          {itemURL && (
            <Box>
              <Typography variant="body1" color={tokens.colors.content.neutral.n30}>
                Item URL: {itemURL}
              </Typography>
            </Box>
          )}
          <Box>
            <Typography variant="body1" color={tokens.colors.content.neutral.n30}>
              Item unique ID: {itemId}
            </Typography>
          </Box>
          <Box m={[tokens.spacing.s4, 0, 0, 0]}>
            <ChatBubble
              type="default"
              message={getContactMessage(flowName, linkId, itemId, itemURL, syncId)}
              onClick={() => {
                handleCopieToClipboard();
                trackEvent(trackingTypes.ACTION, {
                  action_name: trackingTypes.ACTIVITY_LOG.ACTIONS.CONTACT_SUPPORT,
                });
              }}
            >
              Copy text and contact us
            </ChatBubble>
          </Box>
        </Box>
      </Box>
    </>
  );
};

ContactSupportBlock.propTypes = {
  itemId: PropTypes.string.isRequired,
};
