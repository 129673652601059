import { useSelector } from 'react-redux';

import * as featureTypes from '~/consts/features';
import { getFeatureFlagValue } from '~/reducers';

const monthsToSubtractPerVariant = {
  [featureTypes.TEST_MODE_VARIANT.LAST_MONTH]: 1,
  [featureTypes.TEST_MODE_VARIANT.LAST_3_MONTHS]: 3,
  [featureTypes.TEST_MODE_VARIANT.LAST_6_MONTHS]: 6,
  [featureTypes.TEST_MODE_VARIANT.LAST_12_MONTHS]: 12,
};

// we currently have to deal with 2 FFs, hence the logic below might seem funky
// but this was vetted with PM, until we can get rid of the old hasTestModeDefault flag.
const getTestModeDate = (hasTestModeDefault, testModeVariant) => {
  if (Object.keys(monthsToSubtractPerVariant).includes(testModeVariant)) {
    const now = new Date();
    // Create a UTC date at midnight
    const date = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    // remove the number of months specified in the test mode variant using UTC methods
    date.setUTCMonth(date.getUTCMonth() - monthsToSubtractPerVariant[testModeVariant]);
    return date;
  }

  if (hasTestModeDefault || testModeVariant === featureTypes.TEST_MODE_VARIANT.NOW) {
    // Also use UTC for consistency
    const now = new Date();
    return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
  }

  return null;
};

export const useGetDefaultTestModeDate = (dateString) => {
  const hasTestModeDefault = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.TEST_MODE_DEFAULT),
  );
  const testModeVariant = useSelector((state) => getFeatureFlagValue(state, featureTypes.FEATURES.NEW_TEST_MODE));
  if (dateString) {
    return new Date(dateString);
  }
  return getTestModeDate(hasTestModeDefault, testModeVariant);
};
