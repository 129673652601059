import { jwtDecode } from 'jwt-decode';
import { EventTypes } from 'redux-segment';
import Cookies from 'js-cookie';

import { notification } from '@unitoio/mosaic';

import * as loggingActions from '~/actions/logging';
import * as organizationActions from '~/actions/organizations';
import * as websocketActions from '~/actions/websocket';
import * as appTypes from '~/consts/app';
import * as authTypes from '~/consts/auth';
import * as providerTypes from '~/consts/providers';
import * as routes from '~/consts/routes';
import { getToken, getProviderByNamePreferredAuthMethod } from '~/reducers';
import * as localStorage from '~/utils/localStorage';

/**
 *  Rehydrates the auth state by using token from cookie (when the user is already logged in)
 *  @param {string} token - The user token, gathered from the cookie
 */
export const rehydrateAuthState =
  (shouldConnectToWebsocket = true) =>
  async (dispatch, getState) => {
    const state = getState();
    let cookieToken;
    try {
      // On first load, if cookie with token, perform rehydrate to validate that
      // token is still valid.
      cookieToken = Cookies.get(import.meta.env.VITE_UNITO_AUTH_COOKIE_NAME);
      // <Jerky Hack to migrate our cookie> for migration purpose only; can be removed after December 5th
      const isOldFormatCookie = cookieToken && !jwtDecode(cookieToken)?.aud;
      if (isOldFormatCookie) {
        Cookies.remove(import.meta.env.VITE_UNITO_AUTH_COOKIE_NAME);
        Cookies.set(import.meta.env.VITE_UNITO_AUTH_COOKIE_NAME, cookieToken, {
          domain: process.env.NODE_ENV === 'production' ? '.unito.io' : undefined,
        });
      }
      // </Jerky Hack to migrate our cookie>
    } catch (err) {
      dispatch(
        loggingActions.reportException(err, { context: { functionName: 'rehydrateAuthState Cookies.get token' } }),
      );
      dispatch(logoutUser());
    }

    const token = cookieToken || getToken(state);
    if (!token) {
      return null;
    }

    const response = await dispatch({
      types: [
        authTypes.REHYDRATE_AUTH_STATE_REQUEST,
        authTypes.REHYDRATE_AUTH_STATE_SUCCESS,
        authTypes.REHYDRATE_AUTH_STATE_FAILURE,
      ],
      url: routes.API_PATHS.REHYDRATE,
      token,
    });

    if (!response.token) {
      return response;
    }

    let decodedToken;
    try {
      decodedToken = jwtDecode(response.token);
    } catch (err) {
      dispatch(loggingActions.reportException(err, { functionName: 'rehydrateAuthState jwtDecode' }));
      // If cookie token value is malformed, it might crash
      Cookies.remove(import.meta.env.VITE_UNITO_AUTH_COOKIE_NAME, {
        domain: process.env.NODE_ENV === 'production' ? '.unito.io' : undefined,
      });
      dispatch(logoutUser());
    }
    const { _id: userId, email, fullName } = decodedToken;

    try {
      if (shouldConnectToWebsocket) {
        dispatch(websocketActions.connect(response.token));
      }
    } catch (err) {
      dispatch(
        loggingActions.reportException(err, {
          identifier: 'connectionError websocketActions.connect rehydrateAuthState',
        }),
      );
    }

    dispatch({
      type: authTypes.REHYDRATE_AUTH_TOKEN_SUCCESS,
      payload: { ...response, ...decodedToken },
      meta: {
        analytics: {
          eventType: EventTypes.identify,
          eventPayload: {
            userId,
            traits: {
              email,
              name: fullName,
              id: userId,
            },
          },
        },
      },
    });

    return response;
  };

/**
 * LOGOUT
 * @param {*} redirect Optional param, URL to redirect after logout.
 * @returns logoutResponse
 */
export const logoutUser =
  // If you want to revoke the session, pass as parameter "revoke"

  (shouldRevokeSession = false) =>
    async (dispatch) => {
      try {
        localStorage.clearState(appTypes.LOCAL_STORAGE_ORGANIZATION_KEY);
      } catch {
        notification.warning({
          message: 'Local storage error',
          description: "Unito needs to access your browser's local storage for you to be able to switch organizations",
          placement: 'topLeft',
          duration: 0,
        });
      }

      const logoutResponse = await dispatch({
        types: [authTypes.LOGOUT_USER_REQUEST, authTypes.LOGOUT_USER_SUCCESS, authTypes.LOGOUT_USER_FAILURE],
        url: routes.API_PATHS.LOGOUT(shouldRevokeSession),
      });

      // If cookie token value is malformed, it might crash
      Cookies.remove(import.meta.env.VITE_UNITO_AUTH_COOKIE_NAME, {
        domain: process.env.NODE_ENV === 'production' ? '.unito.io' : undefined,
      });

      await dispatch(organizationActions.getFlags());

      if (window.BroadcastChannel) {
        const bcUserEvents = new window.BroadcastChannel('UserEvents');
        bcUserEvents.postMessage({ action: 'logout' });
        bcUserEvents.close();
      }

      return logoutResponse;
    };

export const signin =
  (formValues, { method, authenticationTool, redirect }) =>
  async (dispatch, getState) => {
    const { loginEmail, loginPassword, signupEmail, signupPassword, fullName, keepInformed, ssoIdentityProviderId } =
      formValues;

    const authEmail = method === authTypes.AUTH_ACTIONS.LOGIN ? loginEmail : signupEmail;
    const password = method === authTypes.AUTH_ACTIONS.LOGIN ? loginPassword : signupPassword;

    const preferredMethod = getProviderByNamePreferredAuthMethod(getState(), authenticationTool);

    const authenticateParams = {
      authenticationAction: method,
      authenticationTool,
      authorizationMethod: preferredMethod,
      authenticationOptions: {
        email: authEmail?.trim(), // cognito rejects any email with space characters
        password: authenticationTool === providerTypes.COGNITO ? password : undefined,
        ssoIdentityProviderId: ssoIdentityProviderId?.trim().toLowerCase(),
        fullName,
      },
      authenticationUserProfile: {
        productName: appTypes.PRODUCT_NAMES.PROJECT_SYNC,
        keepInformed,
      },
      redirect,
    };

    return dispatch(authenticate(authenticateParams));
  };

export const authenticate = (payload) => ({
  payload,
  method: 'POST',
  types: [
    authTypes.AUTHENTICATE_USER_REQUEST,
    authTypes.AUTHENTICATE_USER_SUCCESS,
    authTypes.AUTHENTICATE_USER_FAILURE,
  ],
  url: routes.API_PATHS.AUTHENTICATE,
  displayError: false,
});

export const resetPassword = (email) => ({
  payload: { email },
  method: 'POST',
  types: [
    authTypes.RESET_USER_PASSWORD_REQUEST,
    authTypes.RESET_USER_PASSWORD_SUCCESS,
    authTypes.RESET_USER_PASSWORD_FAILURE,
  ],
  url: routes.API_PATHS.RESET_PASSWORD,
  displayError: false,
});

export const resendConfirmationEmail = (email) => ({
  payload: { email },
  method: 'POST',
  types: [
    authTypes.RESEND_CONFIRMATION_REQUEST,
    authTypes.RESEND_CONFIRMATION_SUCCESS,
    authTypes.RESEND_CONFIRMATION_FAILURE,
  ],
  url: routes.API_PATHS.RESEND_CONFIRMATION,
  displayError: false,
});

export const resetPasswordChange = (password, validationId) => ({
  payload: { password },
  method: 'POST',
  types: [
    authTypes.RESET_USER_PASSWORD_CHANGE_REQUEST,
    authTypes.RESET_USER_PASSWORD_CHANGE_SUCCESS,
    authTypes.RESET_USER_PASSWORD_CHANGE_FAILURE,
  ],
  url: routes.API_PATHS.RESET_PASSWORD_CHANGE(validationId),
  displayError: false,
});

export const testConnection = (providerName, { selfSignedCertificate, domain }) => ({
  payload: { selfSignedCertificate, domain },
  method: 'POST',
  types: [authTypes.TEST_CONNECTION_REQUEST, authTypes.TEST_CONNECTION_SUCCESS, authTypes.TEST_CONNECTION_FAILURE],
  url: routes.API_PATHS.TEST_CONNECTION(providerName),
  displayError: false,
});

export const validateDomain = (providerName, domain) => ({
  payload: { domain },
  method: 'POST',
  types: [authTypes.VALIDATE_DOMAIN_REQUEST, authTypes.VALIDATE_DOMAIN_SUCCESS, authTypes.VALIDATE_DOMAIN_FAILURE],
  url: routes.API_PATHS.VALIDATE_DOMAIN(providerName),
  displayError: false,
});

export const setAuthEmbedData = (data) => ({
  payload: { data },
  type: authTypes.SET_AUTH_EMBED_DATA,
});
