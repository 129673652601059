import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import styled from 'styled-components';
import { Icon, tokens, Typography } from '@unitoio/mosaic';
import { withRouter } from 'react-router-dom';

import * as appActions from '~/actions/app';
import * as authActions from '~/actions/auth';
import * as organizationActions from '~/actions/organizations';
import * as trackingActions from '~/actions/tracking';
import * as appTypes from '~/consts/app';
import * as trackingTypes from '~/consts/tracking';
import * as routes from '~/consts/routes';
import {
  getEmbedName,
  getOrganizationName,
  getSelectedOrganizationId,
  getUserAvatarUrl,
  getUserFullName,
  getOrganizations,
  getMaxUsageFeatureToDisplay,
  isOnCustomPlan,
  isOnFreeTrial,
  isOnFreeWithWrikePlan,
  isUserSiteAdmin,
} from '~/reducers';
import { Dropdown, DropdownDivider, DropdownHeader, DropdownItem } from '~/components/Dropdown/Dropdown';
import { Box } from '~/components/Box/Box';
import { Can } from '~/components/Can/Can';
import { LimitProgressBar } from '~/components/LimitProgressBar/LimitProgressBar';
import { Title } from '~/components/Title/Title';
import { IconHoverTooltip } from '~/components/IconHoverTooltip/IconHoverTooltip';

import { capitalize } from '~/utils/capitalize';
import { ProfileAvatar } from './ProfileAvatar';
import { SiteAdminViewSwitch } from './components/SiteAdminViewSwitch';

const FlexDropdownItem = styled.div`
  display: flex;
`;

const StyledIcon = styled(Icon)`
  margin-left: ${tokens.spacing.s3};
`;

class HeaderProfileDropdownComponent extends Component {
  static propTypes = {
    accountAvatarUrl: PropTypes.string,
    accountFullName: PropTypes.string,
    embedName: PropTypes.string,
    isSiteAdmin: PropTypes.bool.isRequired,
    onLogout: PropTypes.func.isRequired,
    fetchItemsUsage: PropTypes.func.isRequired,
    organizations: PropTypes.instanceOf(Map).isRequired,
    selectedOrganizationId: PropTypes.string,
    setSelectedOrganizationId: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
    workspaceName: PropTypes.string,
    featureToShow: PropTypes.instanceOf(Map).isRequired,
    onCustomPlan: PropTypes.bool.isRequired,
    onFreeTrial: PropTypes.bool.isRequired,
    onFreeWithWrikePlan: PropTypes.bool.isRequired,
  };

  state = {
    isLoadingItemsUsage: false,
  };

  static defaultProps = {
    accountAvatarUrl: undefined,
    accountFullName: undefined,
    embedName: undefined,
    selectedOrganizationId: undefined,
    workspaceName: undefined,
  };

  handleToggleHeaderDropdown = async () => {
    const { selectedOrganizationId, fetchItemsUsage } = this.props;
    this.setState({
      isLoadingItemsUsage: true,
    });
    await fetchItemsUsage(selectedOrganizationId);
    this.setState({ isLoadingItemsUsage: false });
  };

  handleOrganizationSwitch = async (newSelectedOrganizationId) => {
    const { trackEvent, setSelectedOrganizationId, selectedOrganizationId } = this.props;
    if (newSelectedOrganizationId !== selectedOrganizationId) {
      trackEvent(trackingTypes.USER_MENU_EVENTS.SWITCH_ORGANIZATION_ACTION, {
        new_organization_id: newSelectedOrganizationId,
        action_name: trackingTypes.USER_MENU_EVENTS.ACTIONS.DIFFERENT_ORGANIZATION,
      });
      await setSelectedOrganizationId(newSelectedOrganizationId);
    }
  };

  handleLogOutClick = () => {
    const { trackEvent, onLogout } = this.props;
    trackEvent(trackingTypes.USER_MENU_EVENTS.ACTION_NAME, {
      action_name: trackingTypes.USER_MENU_EVENTS.ACTIONS.LOG_OUT,
    });
    onLogout();
  };

  render() {
    const {
      accountAvatarUrl,
      accountFullName,
      embedName,
      isSiteAdmin,
      selectedOrganizationId,
      trackEvent,
      workspaceName,
      organizations,
      featureToShow,
      onCustomPlan,
      onFreeTrial,
      onFreeWithWrikePlan,
    } = this.props;

    const { isLoadingItemsUsage } = this.state;

    const isWrikeEmbed = embedName === appTypes.EMBED.WRIKE;

    if (!selectedOrganizationId) {
      return null;
    }

    return (
      <Dropdown
        alignRight
        btnContent={<ProfileAvatar accountAvatarUrl={accountAvatarUrl} colorScheme="dark" />}
        data-test="header__unito-account"
        trackEventOnOpen={() =>
          trackEvent(trackingTypes.USER_MENU_EVENTS.ACTION_NAME, {
            action_name: trackingTypes.USER_MENU_EVENTS.ACTIONS.VIEW,
          })
        }
        onToggle={this.handleToggleHeaderDropdown}
      >
        <DropdownHeader title={accountFullName} subtitle="Logged in as" />

        {isSiteAdmin && <SiteAdminViewSwitch />}
        <DropdownDivider />

        {!embedName && organizations.size > 1 && (
          <DropdownHeader
            title={workspaceName}
            onClick={() =>
              trackEvent(trackingTypes.USER_MENU_EVENTS.SWITCH_ORGANIZATION_ACTION, {
                action_name: trackingTypes.USER_MENU_EVENTS.ACTIONS.SWITCH_ORGANIZATION,
              })
            }
            subtitle="Current workspace"
            subtitleOnMouseOver="Switch workspace"
            trackEventOnBack={() =>
              trackEvent(trackingTypes.USER_MENU_EVENTS.SWITCH_ORGANIZATION_ACTION, {
                action_name: trackingTypes.USER_MENU_EVENTS.ACTIONS.BACK_ORGANIZATION,
              })
            }
          >
            <Dropdown>
              {organizations
                .map((org) =>
                  org.get('id') === selectedOrganizationId ? (
                    <DropdownItem
                      onClick={() =>
                        trackEvent(trackingTypes.USER_MENU_EVENTS.SWITCH_ORGANIZATION_ACTION, {
                          action_name: trackingTypes.USER_MENU_EVENTS.ACTIONS.CURRENT_ORGANIZATION,
                        })
                      }
                      key={org.get('id')}
                    >
                      {org.get('name')}
                      <StyledIcon
                        name="check"
                        kind={Icon.KINDS.SOLID}
                        color={tokens.colors.content.branding.unito}
                        fixedWidth
                      />
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      to={routes.ABSOLUTE_PATHS.DASHBOARD}
                      onClick={() => this.handleOrganizationSwitch(org.get('id'))}
                      key={org.get('id')}
                    >
                      {org.get('name')}
                    </DropdownItem>
                  ),
                )
                .toArray()}
            </Dropdown>
          </DropdownHeader>
        )}

        {!embedName && organizations.size > 1 && <DropdownDivider />}

        <Can I="update" a="billing">
          <DropdownItem
            key="aboutWorkspace"
            to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${selectedOrganizationId}/workspace`}
            data-test="header__btn--workspace"
            onClick={() =>
              trackEvent(trackingTypes.USER_MENU_EVENTS.ACTION_NAME, {
                action_name: trackingTypes.USER_MENU_EVENTS.ACTIONS.WORKSPACE,
              })
            }
          >
            <FlexDropdownItem>
              <Box $p={[0, 0.5, 0, 0]}>
                <Icon name="briefcase" kind={Icon.KINDS.SOLID} fixedWidth />
              </Box>
              About my workspace
            </FlexDropdownItem>
          </DropdownItem>
        </Can>

        <DropdownItem
          key="integrations"
          onClick={() =>
            trackEvent(trackingTypes.USER_MENU_EVENTS.ACTION_NAME, {
              action_name: trackingTypes.SETTINGS_MENU_EVENTS.ACTIONS.CONNECTOR_SECTION,
            })
          }
          to={routes.ABSOLUTE_PATHS.PROFILE}
          data-test="header__btn--integrations"
        >
          <FlexDropdownItem>
            <Box $p={[0, 0.5, 0, 0]}>
              <Icon name="plug" kind={Icon.KINDS.SOLID} fixedWidth />
            </Box>
            Integrations
          </FlexDropdownItem>
        </DropdownItem>

        <DropdownItem
          key="membersAndUsers"
          to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${selectedOrganizationId}/people`}
        >
          <FlexDropdownItem>
            <Box $p={[0, 0.5, 0, 0]}>
              <Icon name="users" kind={Icon.KINDS.SOLID} fixedWidth />
            </Box>
            Members & active users
          </FlexDropdownItem>
        </DropdownItem>

        {!isWrikeEmbed && (
          <Can I="update" a="billing">
            <DropdownItem
              key="Pricing"
              onClick={() =>
                trackEvent(trackingTypes.USER_MENU_EVENTS.ACTION_NAME, {
                  action_name: trackingTypes.USER_MENU_EVENTS.ACTIONS.PRICING,
                })
              }
              to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${selectedOrganizationId}/pricing`}
            >
              <FlexDropdownItem>
                <Box $p={[0, 0.5, 0, 0]}>
                  <Icon name="credit-card" kind={Icon.KINDS.REGULAR} fixedWidth />
                </Box>
                Pricing
              </FlexDropdownItem>
            </DropdownItem>
          </Can>
        )}

        <Can I="update" a="billing">
          <DropdownDivider />

          <DropdownItem
            key="usage"
            onClick={() =>
              trackEvent(trackingTypes.USER_MENU_EVENTS.ACTION_NAME, {
                action_name: trackingTypes.USER_MENU_EVENTS.ACTIONS.BILLING,
              })
            }
            to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${selectedOrganizationId}/usage`}
            data-test="header__btn--usage"
          >
            <FlexDropdownItem>
              <Box $p={[0, 0.5, 0, 0]}>
                <Icon name="list" kind={Icon.KINDS.SOLID} fixedWidth />
              </Box>
              Usage & billing
            </FlexDropdownItem>
            {isLoadingItemsUsage && (
              <Icon
                color={tokens.colors.content.positive.default}
                kind={Icon.KINDS.SOLID}
                name="spinner"
                title="loading"
                pulse
              />
            )}
            {(!isLoadingItemsUsage && onFreeTrial) || onCustomPlan || onFreeWithWrikePlan ? (
              <div>
                <Title type="h5">
                  {capitalize(featureToShow.get('displayName'))}{' '}
                  {featureToShow.get('helpText') && (
                    <IconHoverTooltip placement="top">{featureToShow.get('helpText')}</IconHoverTooltip>
                  )}
                </Title>
                <Typography>
                  {' '}
                  <strong> {featureToShow.get('usage')} </strong>{' '}
                </Typography>
              </div>
            ) : (
              <LimitProgressBar
                currentValue={featureToShow.get('usage')}
                helpText={featureToShow.get('helpText')}
                limitName={capitalize(featureToShow.get('displayName'))}
                limitValue={featureToShow.get('limit')}
              />
            )}
          </DropdownItem>
        </Can>

        <DropdownDivider />

        {!embedName && (
          <DropdownItem key="logout" data-test="header__btn--log-out" onClick={this.handleLogOutClick}>
            Log out
          </DropdownItem>
        )}
      </Dropdown>
    );
  }
}

const mapStateToProps = (state) => {
  const selectedOrganizationId = getSelectedOrganizationId(state);
  const embedName = getEmbedName(state);
  /* Note we need to check for siteAdmin via the reducer here
   * instead of the SiteAdmin context because this is used for the SiteAdmin Switch
   * which drives the isSiteAdminViewEnabled flag, the SiteAdminContext uses this value directly
   * for its returned isSiteAdmin value */
  const isSiteAdmin = isUserSiteAdmin(state);
  return {
    accountAvatarUrl: getUserAvatarUrl(state),
    accountFullName: getUserFullName(state),
    embedName,
    isSiteAdmin,
    workspaceName: getOrganizationName(state, selectedOrganizationId),
    organizations: getOrganizations(state),
    onCustomPlan: isOnCustomPlan(state, selectedOrganizationId),
    onFreeTrial: isOnFreeTrial(state, selectedOrganizationId),
    onFreeWithWrikePlan: isOnFreeWithWrikePlan(state, selectedOrganizationId),
    selectedOrganizationId,
    featureToShow: getMaxUsageFeatureToDisplay(state, selectedOrganizationId),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(authActions.logoutUser()),
  trackEvent: (...params) => dispatch(trackingActions.trackEvent(...params)),
  setSelectedOrganizationId: (newSelectedOrganization) =>
    dispatch(appActions.setSelectedOrganizationId(newSelectedOrganization)),
  fetchItemsUsage: (organizationId) => dispatch(organizationActions.getItemsUsage(organizationId)),
});

export const HeaderProfileDropdown = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderProfileDropdownComponent),
);
