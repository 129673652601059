import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Map } from 'immutable';

import { Typography, NewAlert as Alert, NewAlertLevel } from '@unitoio/mosaic';

import * as authUtils from '~/utils/auth';
import { Href } from '~/components/Href/Href';
import { PasswordInput } from '~/components/PasswordInput/PasswordInput';
import { TextAreaInput } from '~/components/TextAreaInput/TextAreaInput';
import { TextInput } from '~/components/TextInput/TextInput';

import { RadioButtonGroupGithubApp } from './instructions/githubappinstallation/RadioButtonGroupGithubApp';

PCDInstructionFields.propTypes = {
  provider: PropTypes.instanceOf(Map).isRequired,
  authorizationType: PropTypes.string.isRequired,
  error: PropTypes.string,
};

function getInputComponent(field) {
  if (field.get('type') === 'enum') {
    // TODO: This is specific to the github app
    return RadioButtonGroupGithubApp;
  }

  const isTextArea = field.get('type') === 'string' && field.get('multiline');
  if (isTextArea) {
    return TextAreaInput;
  }

  const fieldName = field.get('fieldName', '').toLowerCase();
  if (fieldName.includes('password') || fieldName.includes('token') || fieldName.includes('secret')) {
    return PasswordInput;
  }

  return TextInput;
}

export function PCDInstructionFields({ provider, authorizationType, error }) {
  const authMethodFields = authUtils.getProviderAuthorizationMethodFields(provider, authorizationType);

  return (
    <>
      {authMethodFields
        .map((field) => (
          <Field
            key={field.get('fieldName')}
            component={getInputComponent(field)}
            name={field.get('fieldName')}
            props={{
              label: (
                <Typography variant="body1">
                  {field.get('label')} {field.get('required') ? <small>(Required)</small> : null}
                </Typography>
              ),
              helpText: (field.get('help') || field.get('unitoDocUrl')) && (
                <>
                  {field.get('help')} {field.get('help') && field.get('unitoDocUrl') ? '. ' : null}
                  {field.get('unitoDocUrl') ? <Href href={field.get('unitoDocUrl')}>More details</Href> : null}
                </>
              ),
              placeholder: field.get('placeholder'),
              type: field.get('type'),
            }}
          />
        ))
        .toArray()}
      {error && <Alert level={NewAlertLevel.ERROR} message={error} />}
    </>
  );
}
