import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { getCountry } from 'country-state-picker';

import { Box, Input, Modal, Select, tokens, Typography, TypographyVariants } from '@unitoio/mosaic';

import * as billingActions from '~/actions/billing';
import { getPlanProfile, getActiveCoupon, getMaxUsageFeatureToDisplay } from '~/reducers';
import * as trackingTypes from '~/consts/tracking';
import * as billingTypes from '~/consts/billing';
import * as featureTypes from '~/consts/features';

import PoweredByStripe from '~/images/poweredbystripe.svg';
import Discover from '~/images/Discover.svg';
import Interac from '~/images/Interac.svg';
import Visa from '~/images/Visa.svg';
import Mastercard from '~/images/Mastercard.svg';
import Amex from '~/images/Amex.svg';
import { RadioButtonGroup } from '~/components/RadioButtonGroup/RadioButtonGroup';
import { RadioButton } from '~/components/RadioButton/RadioButton';

import { getCountryOptions } from './utils';

const Disclaimer = styled(Typography)`
  color: ${tokens.colors.content.neutral.n30};
`;

const CreditCards = styled.span`
  &:last-child {
    margin-right: 0;
  }
`;

const CreditCard = styled.img`
  height: ${tokens.spacing.s5};
  margin-right: ${tokens.spacing.s2};
`;

const Grid = styled.div`
  display: grid;
  gap: ${tokens.spacing.s4};
  grid-template-columns: repeat(8, 1fr);
`;

const Cell = styled.div`
  grid-column: ${(props) => (props.$span ? `span ${props.$span}` : 'span 1')} / auto;
`;

// these are directly copied of mimics <Input/> component
// the component doesn't take children so we have this wrapper for stripe elements
// stripe style object doesn't allow these overrides
// https://stripe.com/docs/js/appendix/style
const StripeElementContainer = styled(Box)`
  height: ${tokens.lineHeight.lh2};
  border: 1px solid ${tokens.colors.content.neutral.n20};
  padding: calc((2.5rem - 16px) / 2);
  margin-top: ${tokens.spacing.s2};
  line-height: ${tokens.lineHeight.lh4};
  border-radius: ${tokens.spacing.s2};
  color: ${tokens.colors.content.neutral.n40};
  width: 100%;
  box-sizing: border-box;
`;

const ErrorMsg = styled(Typography)`
  color: ${tokens.colors.content.destructive.default};
  visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 40px;
`;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: tokens.colors.global.primary.dark,
      '::placeholder': {
        color: tokens.colors.content.neutral.n20,
      },
    },
    invalid: {
      color: tokens.colors.content.destructive.default,
      iconColor: tokens.colors.content.destructive.default,
    },
  },
};

const mapFieldToLabel = {
  cardNumber: 'Card number',
  cardCvc: 'CVC',
  cardExpiry: 'Expiration',
  addressZip: 'Postal/Zip code',
  addressCountry: 'Country',
  phoneNumber: 'Phone number (optional)',
};

export const getPriceWithDiscount = (plan, coupon) => {
  let amountInCents = plan.get('amount');
  const couponPercentOff = coupon.get('percentOff');
  const couponAmountOff = coupon.get('amountOff');

  if (couponPercentOff) {
    if (couponPercentOff < 100 && couponPercentOff > 0) {
      // percentOff is a number 10, not decimal number. for example, for 10%, it will be 10
      const discountPercentage = couponPercentOff / 100;
      const discount = amountInCents * discountPercentage;
      amountInCents -= discount;
    }
  } else if (couponAmountOff) {
    amountInCents -= couponAmountOff;
  }

  return (amountInCents / 100).toFixed(2);
};

const useGetPlanDetails = (planId, orgCustomerId, organizationId) => {
  // example of plan Id '20210405-personal5-month'
  const planIntervalFromProp = planId.split('-')[2];
  const monthlyPlanId =
    planIntervalFromProp === billingTypes.PLAN_INTERVALS.MONTH ? planId : planId.replace('year', 'month');
  const yearlyPlanId =
    planIntervalFromProp === billingTypes.PLAN_INTERVALS.MONTH ? planId.replace('month', 'year') : planId;
  const monthlyPlan = useSelector((state) => getPlanProfile(state, monthlyPlanId));
  const yearlyPlan = useSelector((state) => getPlanProfile(state, yearlyPlanId));

  const coupon = useSelector((state) => getActiveCoupon(state, orgCustomerId, organizationId));

  const [planName, planLimit] = (yearlyPlan.get('nickname') || yearlyPlan.get('name')).split(' ');

  return {
    yearlyPlanId,
    monthlyPlanId,
    planName,
    planLimit,
    yearlyPlanAmount: getPriceWithDiscount(yearlyPlan, coupon),
    monthlyPlanAmount: getPriceWithDiscount(monthlyPlan, coupon),
    planIntervalFromProp,
    planYearlySaving: yearlyPlan.getIn(['metadata', 'yearlySaved'], null),
  };
};

export const PaymentDetailsStep = ({
  onSuccess,
  defaultValues,
  onPreviousStep,
  isOpen,
  onClose,
  planId: planIdProp,
  orgCustomerId,
  organizationId,
  trackEvent,
}) => {
  const {
    yearlyPlanId,
    monthlyPlanId,
    planName,
    planLimit,
    yearlyPlanAmount,
    monthlyPlanAmount,
    planIntervalFromProp,
    planYearlySaving,
  } = useGetPlanDetails(planIdProp, orgCustomerId, organizationId);
  const valueMetricId = useSelector((state) => getMaxUsageFeatureToDisplay(state, organizationId))?.get('id');

  const [paymentInfoError, setPaymentInfoError] = useState(null);
  const [planInterval, setPlanInterval] = useState(planIntervalFromProp);

  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm({ defaultValues });

  const isYearlyPlanSelected = planInterval === billingTypes.PLAN_INTERVALS.YEAR;
  const isOnItemInSyncPlan = valueMetricId === featureTypes.FEATURES.MAX_ITEMS_KEPT_IN_SYNC;
  const planId = planInterval === billingTypes.PLAN_INTERVALS.YEAR ? yearlyPlanId : monthlyPlanId;

  const payYearlyLabel = planYearlySaving ? `Pay yearly (save $${planYearlySaving})` : 'Pay yearly';
  const amountPerMonthText = isYearlyPlanSelected
    ? `$${(yearlyPlanAmount / 12).toFixed(2)} `
    : ` $${monthlyPlanAmount}`;
  const planIntervalText = isYearlyPlanSelected ? 'yearly' : 'monthly';
  const confirmTotalAmountText = `Confirm payment: $${isYearlyPlanSelected ? yearlyPlanAmount : monthlyPlanAmount} USD`;

  useEffect(() => {
    if (defaultValues?.addressCountry) {
      setValue('addressCountry', defaultValues.addressCountry, { shouldDirty: true });
    }
    if (defaultValues?.addressZip) {
      setValue('addressZip', defaultValues.addressZip, { shouldDirty: true });
    }
  }, [defaultValues?.addressCountry, defaultValues?.addressZip, setValue]);

  useEffect(() => {
    setPlanInterval(planIntervalFromProp);
  }, [planIntervalFromProp, setPlanInterval]);

  const getPhoneNumberPlaceholder = () => {
    if (!defaultValues?.addressCountry) {
      return '';
    }

    const country = getCountry(defaultValues.addressCountry);
    return country?.dial_code;
  };

  const onSubmit = async (formValues) => {
    if (formValues.phoneNumber) {
      const phone = formValues.phoneNumber;

      dispatch(
        billingActions.editOrganization({
          phone,
        }),
      );
    }
    const tokenData = {
      address_country: formValues.addressCountry,
      address_zip: formValues.addressZip.trim(),
    };
    const cardNumber = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(cardNumber, tokenData);
    if (result.error) {
      const { message } = result.error;

      trackEvent(trackingTypes.BLOCKED, { reason: message });
      trackEvent(trackingTypes.SUBMIT);
      return setPaymentInfoError(message);
    }

    trackEvent(trackingTypes.SUBMIT);

    return onSuccess(planId, result.token);
  };

  const onError = (formErrors) => {
    const missingFields = Object.keys(formErrors).reduce((acc, field) => {
      if (formErrors[field].type === 'required') {
        return [...acc, mapFieldToLabel[field]];
      }
      return acc;
    }, []);

    if (missingFields.length > 0) {
      trackEvent(trackingTypes.BLOCKED, { reason: `${missingFields.join(',')} are missing.` });
    }
  };

  const handleClickBackBtn = () => {
    trackEvent(trackingTypes.ACTION, { action_name: 'clicked on back button' });
    onPreviousStep();
  };

  const handleOnClose = () => {
    reset();
    onClose();
  };

  const handlePlanIntervalOnChange = (value) => {
    const newPlanId = value === billingTypes.PLAN_INTERVALS.MONTH ? monthlyPlanId : yearlyPlanId;
    trackEvent(trackingTypes.ACTION, { action_name: `changed billing interval to ${value}`, plan_selected: newPlanId });
    trackEvent(trackingTypes.USER_PRICING_EVENTS.USER_CLICKED_BILLING_INTERVAL, {
      interval: value,
      plan_selected: newPlanId,
    });

    setPlanInterval(value);
    setValue('planInterval', value, { shouldDirty: true });
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Confirm purchase"
      confirmLabel={confirmTotalAmountText}
      cancelLabel="Back"
      onConfirm={handleSubmit(onSubmit, onError)}
      onCancel={handleClickBackBtn}
      onRequestClose={handleOnClose}
      isConfirmButtonDisabled={isSubmitting}
    >
      <Box>
        {/* Ideally we should be using a label element here that is hooked up to the
        input element from Stripe. However, Stripe currently doesn't offer this
        options. https://stripe.com/docs/stripe-js/react#element-components */}
        <Typography variant={TypographyVariants.H4}>Summary</Typography>
        <Controller
          render={({ field: { value } }) => (
            <RadioButtonGroup
              name="paymentRadioButtonGroup"
              onChange={handlePlanIntervalOnChange}
              value={value}
              btnAlignment="horizontal"
              inline
            >
              <RadioButton
                value={billingTypes.PLAN_INTERVALS.MONTH}
                label="Pay monthly"
                color={tokens.colors.content.primary.default}
              />
              <RadioButton
                value={billingTypes.PLAN_INTERVALS.YEAR}
                label={payYearlyLabel}
                color={tokens.colors.content.primary.default}
              />
            </RadioButtonGroup>
          )}
          control={control}
          name="planInterval"
          rules={{ required: true }}
          defaultValue={planIntervalFromProp}
        />

        <Box
          p={[tokens.spacing.s4, tokens.spacing.s5]}
          m={[tokens.spacing.s3, tokens.spacing.s0, tokens.spacing.s0]}
          borderColor={tokens.colors.content.neutral.n10}
          borderRadius={tokens.spacing.s3}
        >
          <Typography>
            {planName} plan including {planLimit} {isOnItemInSyncPlan ? 'items in sync' : 'active users'}
          </Typography>
          <Disclaimer variant={TypographyVariants.BODY2}>
            {amountPerMonthText} per month, billed {planIntervalText}
          </Disclaimer>
        </Box>
      </Box>

      <Box m={[tokens.spacing.s5, tokens.spacing.s0]} justifyContent="flex-start">
        <Typography variant={TypographyVariants.H4}>Card details</Typography>
        <CreditCards>
          <CreditCard src={Visa} alt="visa" />
          <CreditCard src={Mastercard} alt="mastercard" />
          <CreditCard src={Amex} alt="amex" />
          <CreditCard src={Discover} alt="discover" />
          <CreditCard src={Interac} alt="interac" />
        </CreditCards>
      </Box>

      <Grid>
        <Cell $span="4">
          <Typography variant={TypographyVariants.BODY1}>{mapFieldToLabel.cardNumber}</Typography>

          <Controller
            render={({ field: { onChange } }) => (
              <StripeElementContainer m={[tokens.spacing.s2, 0, 0, 0]}>
                <CardNumberElement onChange={onChange} id="card-number-element" />
              </StripeElementContainer>
            )}
            control={control}
            name="cardNumber"
            rules={{ required: true }}
          />
          <ErrorMsg variant={TypographyVariants.BODY2} $visible={!!errors.cardNumber}>
            Required
          </ErrorMsg>
        </Cell>

        <Cell $span="2">
          <Typography variant={TypographyVariants.BODY1}>{mapFieldToLabel.cardExpiry}</Typography>

          <Controller
            render={({ field: { onChange } }) => (
              <StripeElementContainer>
                <CardExpiryElement onChange={onChange} id="card-expiry-element" options={CARD_ELEMENT_OPTIONS} />
              </StripeElementContainer>
            )}
            control={control}
            name="cardExpiry"
            rules={{ required: true }}
          />
          <ErrorMsg variant={TypographyVariants.BODY2} $visible={!!errors.cardExpiry}>
            Required
          </ErrorMsg>
        </Cell>

        <Cell $span="2">
          <Typography variant={TypographyVariants.BODY1}>{mapFieldToLabel.cardCvc}</Typography>
          <Controller
            render={({ field: { onChange } }) => (
              <StripeElementContainer>
                <CardCvcElement id="card-cvc-element" onChange={onChange} options={CARD_ELEMENT_OPTIONS} />
              </StripeElementContainer>
            )}
            control={control}
            name="cardCvc"
            rules={{ required: true }}
          />
          <ErrorMsg variant={TypographyVariants.BODY2} $visible={!!errors.cardCvc}>
            Required
          </ErrorMsg>
        </Cell>

        <Cell $span="3">
          <Typography variant={TypographyVariants.BODY1}>{mapFieldToLabel.addressCountry}</Typography>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Box m={[tokens.spacing.s2, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0]}>
                <Select
                  value={value}
                  onChange={onChange}
                  options={getCountryOptions()}
                  placeholder="Choose a country"
                  searchable
                  size="md"
                />
              </Box>
            )}
            control={control}
            name="addressCountry"
            rules={{ required: true }}
          />
          <ErrorMsg variant={TypographyVariants.BODY2} $visible={!!errors.addressCountry}>
            Required
          </ErrorMsg>
        </Cell>

        <Cell $span="2">
          <Typography variant={TypographyVariants.BODY1}>{mapFieldToLabel.addressZip}</Typography>
          <Controller
            render={({ field }) => (
              <Input id="addressZip" size="large" maxLength={20} placeholder={mapFieldToLabel.addressZip} {...field} />
            )}
            control={control}
            name="addressZip"
            rules={{ required: true }}
          />
          <ErrorMsg variant={TypographyVariants.BODY2} $visible={!!errors.addressZip}>
            Required
          </ErrorMsg>
        </Cell>

        <Cell $span="3">
          <Typography variant={TypographyVariants.BODY1}>{mapFieldToLabel.phoneNumber}</Typography>
          <Controller
            render={({ field }) => (
              <Input id="phone-number" size="large" maxLength={20} prefix={getPhoneNumberPlaceholder()} {...field} />
            )}
            control={control}
            name="phoneNumber"
          />
        </Cell>
      </Grid>

      <ErrorMsg variant={TypographyVariants.BODY2} $visible={!!paymentInfoError}>
        {paymentInfoError}
      </ErrorMsg>

      <Footer>
        <img src={PoweredByStripe} height={24} alt="powered by stripe" />
      </Footer>
    </Modal>
  );
};

PaymentDetailsStep.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onPreviousStep: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    addressCountry: PropTypes.string,
    addressZip: PropTypes.string,
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  planId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  orgCustomerId: PropTypes.string.isRequired,
  trackEvent: PropTypes.func.isRequired,
};
