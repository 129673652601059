import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Typography } from '@unitoio/mosaic';

import * as authTypes from '~/consts/auth';
import * as authUtils from '~/utils/auth';
import { getSearchParams } from '~/utils/getSearchParams';

import { PCDInstructionFields } from './PCDInstructionFields';
import * as ProviderInstructions from './instructions';

class AuthInstructionsComponent extends Component {
  static propTypes = {
    authorizationType: PropTypes.string.isRequired,
    domain: PropTypes.string,
    error: PropTypes.string,
    location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({ providerName: PropTypes.string, method: PropTypes.string }),
    }).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    provider: PropTypes.instanceOf(Map).isRequired,
    trackEvent: PropTypes.func.isRequired,
  };

  static defaultProps = {
    domain: undefined,
    error: undefined,
  };

  getErrorMessage = () => {
    const {
      error,
      location: { search },
    } = this.props;

    const query = getSearchParams(search);
    return error || query.message;
  };

  trackEvent = (actionName) => {
    const { trackEvent } = this.props;
    trackEvent('ONBOARDING_SUBMIT', {
      action_name: actionName,
      onboarding_version: '202004',
    });
  };

  isEmbedSignup = () => {
    const {
      location: { search },
      match,
    } = this.props;
    const query = getSearchParams(search);
    return match?.params?.method === authTypes.AUTH_ACTIONS.SIGNUP && query?.embed === true;
  };

  render() {
    const { authorizationType, domain, history, match, onCancel, onSubmit, provider } = this.props;

    const Instructions = ProviderInstructions[match.params.providerName];

    const error = this.getErrorMessage();
    const isEmbedSignup = this.isEmbedSignup();

    if (!isEmbedSignup && !authUtils.getProviderConnectionParameters(provider).isEmpty() && !domain) {
      return <Redirect to=".." />;
    }

    return (
      <Fragment>
        <Typography align="center" variant="h2">
          Connect {provider.get('displayName')} with Unito
        </Typography>
        <Instructions
          authorizationType={authorizationType}
          error={error}
          history={history}
          match={match}
          onCancel={onCancel}
          onSubmit={onSubmit}
          provider={provider}
          providerName={provider.get('name')}
          trackEvent={this.trackEvent}
          isEmbed={isEmbedSignup}
        >
          <PCDInstructionFields provider={provider} authorizationType={authorizationType} error={error} />
        </Instructions>
      </Fragment>
    );
  }
}

const formSelector = formValueSelector('authentication');

const mapStateToProps = (state) => {
  const authorizationType = formSelector(state, 'authorizationMethod');

  return {
    authorizationType,
    domain: formSelector(state, 'domain'),
  };
};

export const AuthInstructions = connect(mapStateToProps)(AuthInstructionsComponent);
