import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import {
  Box,
  Form,
  Icon,
  Input,
  NewModal as Modal,
  Space,
  Typography,
  TypographyVariants,
  tokens,
} from '@unitoio/mosaic';

import * as containerActions from '~/actions/containers';
import { WorkspacesSelectRevamp } from '~/components/WorkspacesSelectRevamp/WorkspacesSelectRevamp';

const LowercaseContainerTerm = styled.span`
  text-transform: lowercase;
`;

const CapitalizeContainerTerm = styled.span`
  text-transform: capitalize;
`;
export function NewProjectModalRevamp({
  closeModal,
  containerTerm,
  containerType,
  isOpen,
  itemType,
  handleOnChangeNewContainer,
  providerDisplayName,
  providerIdentityId,
  workspaceTerm,
}) {
  const { control, formState, handleSubmit, watch, reset } = useForm();
  const [isSaving, setIsSaving] = useState(false);
  const newProjectNameWatch = watch('newProjectName');
  const newProjectWorkspaceWatch = watch('newProjectWorkspace');
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const { newProjectName } = data;

    const newProjectWorkspaceId = data?.newProjectWorkspace;
    try {
      setIsSaving(true);
      const { container } = await dispatch(
        containerActions.createContainer(
          newProjectName,
          newProjectWorkspaceId,
          providerIdentityId,
          containerType,
          itemType,
        ),
      );
      handleOnChangeNewContainer(container);
      closeModal();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.err(err);
    } finally {
      setIsSaving(false);
    }
  };

  const onClose = () => {
    reset((formValues) => ({
      ...formValues,
    }));
    closeModal();
  };

  return (
    <Modal
      okButtonProps={{
        disabled:
          !newProjectNameWatch || (workspaceTerm && !newProjectWorkspaceWatch) || formState.isSubmitting || isSaving,
        loading: isSaving,
        onClick: handleSubmit(onSubmit),
        size: 'lg',
        variant: 'primary',
      }}
      okText="Create"
      cancelText="Cancel"
      cancelButtonProps={{
        disabled: isSaving,
        onClick: onClose,
        size: 'lg',
        variant: 'secondary',
      }}
      closable
      open={isOpen}
      onClose={onClose}
      title={
        <>
          Create a new {providerDisplayName} <LowercaseContainerTerm>{containerTerm}</LowercaseContainerTerm>
        </>
      }
    >
      <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s6, tokens.spacing.s0]}>
        <Typography variant={TypographyVariants.BODY1}>
          <CapitalizeContainerTerm>{containerTerm}</CapitalizeContainerTerm> name
        </Typography>
        <Controller
          control={control}
          name="newProjectName"
          render={({ field }) => <Input maxLength={120} id="newProjectName" placeholder="Project name" {...field} />}
        />
      </Box>
      {workspaceTerm && (
        <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s6, tokens.spacing.s0]}>
          <Typography variant={TypographyVariants.BODY1}>
            In which {workspaceTerm} should the <LowercaseContainerTerm>{containerTerm}</LowercaseContainerTerm> be
            created
          </Typography>

          <WorkspacesSelectRevamp
            control={control}
            name="newProjectWorkspace"
            providerIdentityId={providerIdentityId}
            workspaceTerm={workspaceTerm}
            providerDisplayName={providerDisplayName}
          />
        </Box>
      )}
    </Modal>
  );
}

NewProjectModalRevamp.propTypes = {
  containerType: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleOnChangeNewContainer: PropTypes.func.isRequired,
  providerIdentityId: PropTypes.string,
  providerDisplayName: PropTypes.string.isRequired,
  containerTerm: PropTypes.string.isRequired,
  workspaceTerm: PropTypes.string.isRequired,
};
