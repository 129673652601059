import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Icon, Link, Typography, tokens } from '@unitoio/mosaic';

import * as trackingTypes from '~/consts/tracking';
import { ChatBubble } from '~/components/ChatBubble/ChatBubble';

const StyledLink = styled(Link)`
  color: ${tokens.colors.global.primary.dark} !important;
`;

const StyledIcon = styled(Icon)`
  margin-right: ${tokens.spacing.s4};
`;

const resourceList = [
  {
    icon: 'books',
    label: 'Resources library',
    url: 'https://unito.io/resources/',
  },
  {
    icon: 'map-location',
    label: 'Roadmap and feature requests',
    url: 'https://unito.canny.io/',
  },
  {
    icon: 'file-magnifying-glass',
    label: "Learn from Unito's case studies",
    url: 'https://unito.io/case-studies/',
  },
];

const Ressource = ({ icon, label, url, trackEvent }) => (
  <Box
    m={[0, 0, tokens.spacing.s4, 0]}
    borderRadius={tokens.spacing.s3}
    borderSize={1}
    p={[tokens.spacing.s1, tokens.spacing.s3, tokens.spacing.s1, tokens.spacing.s4]}
    fullWidth
    alignItems="center"
  >
    <Box m={[0, 0, 0, 0]}>
      <Typography color={tokens.colors.content.neutral.n40} variant="body1">
        <StyledIcon name={icon} kind={Icon.KINDS.REGULAR} />
        {label}
      </Typography>
    </Box>
    <Box m={[tokens.spacing.s3, tokens.spacing.s5, tokens.spacing.s3, 'auto']}>
      <StyledLink
        isExternalLink
        href={url}
        onClick={() =>
          trackEvent(trackingTypes.ACTION, {
            action_name: trackingTypes.FLOW_BUILDER_SIDE_PANEL.ACTIONS.CLICKED_ARTICLE,
            article_url: url,
          })
        }
      >
        Open
      </StyledLink>
    </Box>
  </Box>
);

Ressource.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  trackEvent: PropTypes.func.isRequired,
};

export const ResourceBlock = ({ trackEvent }) => (
  <>
    <Box m={[0, 0, tokens.spacing.s4, 0]}>
      <Typography variant="h4">Get even more help</Typography>
    </Box>

    {resourceList.map((ressource) => (
      <Ressource key={ressource.label} {...ressource} trackEvent={trackEvent} />
    ))}

    <Box
      m={[0, 0, tokens.spacing.s4, 0]}
      borderRadius={tokens.spacing.s3}
      borderSize={1}
      p={[tokens.spacing.s1, tokens.spacing.s3, tokens.spacing.s1, tokens.spacing.s4]}
      fullWidth
      alignItems="center"
    >
      <Box m={[0, 0, 0, 0]}>
        <Typography variant="body1" color={tokens.colors.content.neutral.n40}>
          <StyledIcon name="comment" kind={Icon.KINDS.REGULAR} />
          Contact support
        </Typography>
      </Box>
      <Box m={[0, 0, 0, 'auto']}>
        <ChatBubble
          type="text"
          onClick={() =>
            trackEvent(trackingTypes.ACTION, {
              action_name: trackingTypes.FLOW_BUILDER_SIDE_PANEL.ACTIONS.CONTACT_SUPPORT,
            })
          }
        >
          Open
        </ChatBubble>
      </Box>
    </Box>
  </>
);

ResourceBlock.propTypes = {
  trackEvent: PropTypes.func.isRequired,
};
