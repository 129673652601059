import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Button, tokens, Spinner, Typography, TypographyVariants } from '@unitoio/mosaic';

import { useTrackStartEvent } from '~/hooks/useTrackStartEvent';
import { capitalize } from '~/utils/capitalize';

import { useGetAutopopulateFields } from './hooks';
import { useGetProviderTerms } from '../../hooks/useGetProviderTerms';
import { AutopopulateContentLayout } from './AutopopulateContentLayout';
import AutopopulateAnimation from './images/Autopopulate_animation.gif';

export const AUTOPOPULATE_ALT_TEXT = 'gif representing the auto-populate feature';

const AutopopulateImg = styled.img`
  max-width: 55%;
  height: auto;
  align-self: center;
`;

const AutopopulateDescription = styled(Typography)`
  margin-top: ${tokens.spacing.s3};
  margin-bottom: ${tokens.spacing.s4};
  max-width: 65%;
`;

export function AutopopulateContent({ linkId, backToMappingUrl, side }) {
  const formData = useWatch();
  const history = useHistory();
  const terminologySideA = useGetProviderTerms(formData.A.providerName, formData.A.itemType, formData.A.containerType);
  const terminologySideB = useGetProviderTerms(formData.B.providerName, formData.B.itemType, formData.B.containerType);
  const [originatorTerminology, targetTerminology] =
    side === 'B' ? [terminologySideA, terminologySideB] : [terminologySideB, terminologySideA];
  const goBackToMapping = () => history.push(`${backToMappingUrl}`);
  const [isAutopopulating, autopopulateFields] = useGetAutopopulateFields(linkId, goBackToMapping);
  useTrackStartEvent();

  if (isAutopopulating) {
    return (
      <AutopopulateContentLayout
        title={<Typography variant={TypographyVariants.H3}>Decide what fields sync</Typography>}
        image={<AutopopulateImg src={AutopopulateAnimation} alt={AUTOPOPULATE_ALT_TEXT} />}
      >
        <>
          <Typography variant="h5" align="center">
            Mapping your fields...
          </Typography>
          <AutopopulateDescription variant="body1" align="center">
            We're connecting fields between your tools. This will take a few seconds...
          </AutopopulateDescription>
          <Spinner />
        </>
      </AutopopulateContentLayout>
    );
  }

  return (
    <AutopopulateContentLayout
      title={<Typography variant={TypographyVariants.H3}>Decide what fields sync</Typography>}
      image={<AutopopulateImg src={AutopopulateAnimation} alt={AUTOPOPULATE_ALT_TEXT} />}
    >
      <>
        <Typography variant="h5" align="center">
          🚀 Auto-populate {targetTerminology.itemName.plural} in {targetTerminology.providerDisplayName}
        </Typography>
        <AutopopulateDescription variant="body1" align="center">
          {capitalize(originatorTerminology.itemName.plural)} from {originatorTerminology.providerDisplayName} will
          become {targetTerminology.itemName.plural} in your {targetTerminology.providerDisplayName}{' '}
          {targetTerminology.containerName.singular}. Unito will then automatically map them. The next step is for you
          to review and confirm.
        </AutopopulateDescription>
        <Button variant={Button.variants.SECONDARY} onClick={autopopulateFields}>
          Auto-populate mappings
        </Button>
      </>
    </AutopopulateContentLayout>
  );
}

AutopopulateContent.propTypes = {
  linkId: PropTypes.string.isRequired,
  backToMappingUrl: PropTypes.string.isRequired,
  side: PropTypes.oneOf(['A', 'B']).isRequired,
};
