import React from 'react';
import styled from 'styled-components';

import { Box, tokens, Typography } from '@unitoio/mosaic';

import LogoutIllustration from '~/images/LogoutIllustration.svg';

const StyledBox = styled(Box)`
  min-height: 90vh;
`;

export const WorkspaceDeletedLogoutPage = () => (
  <StyledBox alignItems="center" justifyContent="center" flexDirection="column">
    <Box m={[0, 0, tokens.spacing.s6, 0]}>
      <img src={LogoutIllustration} alt="LogoutIllustration" />
    </Box>
    <Box m={[0, 0, tokens.spacing.s4, 0]}>
      <Typography variant="h2">Your workspace has been successfully closed!</Typography>
    </Box>
    <Box>
      <Typography variant="body1">Thanks for using Unito. You can now close this tab.</Typography>
    </Box>
  </StyledBox>
);
