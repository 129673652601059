import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Icon, Box, NewTooltip, tokens, Typography, TypographyVariants } from '@unitoio/mosaic';

import * as linkTypes from '~/consts/link';
import * as fieldTypes from '~/consts/fields';
import * as pcdFilterOperatorTypes from '~/consts/pcdFilterOperator';
import { areCustomFieldsLoaded } from '~/reducers';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';
import { TurnOffTestModeModal } from '~/components/TurnOffTestModeModal/TurnOffTestModeModal';

import { RulesRowSpecialField } from './RulesRowSpecialField';
import { RulesRowNormalField } from './RulesRowNormalField';
import { TestModeRuleTooltip } from '../pages/Rules/RulesSection/TestModeRuleTooltip/TestModeRuleTooltip';

const StretchedBox = styled((props) => <Box {...props} />)`
  width: 100%;
  min-width: 0;
  gap: ${tokens.spacing.s3};
  &:hover {
    border-color: ${({ $hoverable }) =>
      $hoverable ? tokens.colors.content.primary.default : tokens.colors.global.primary.light};
  }
`;

const MaxWidthInput = styled((props) => <Box {...props} />)`
  max-width: 100%;
  min-width: 0;
`;

const isSpecialField = (fieldId) =>
  [
    fieldTypes.INCLUDE_CLOSED_TASKS,
    fieldTypes.EARLIEST_CREATED_AT,
    fieldTypes.SUBFOLDERS,
    fieldTypes.DEEP_FILTER_ITEM,
    fieldTypes.PAST_DATE,
    fieldTypes.FUTURE_DATE,
  ].includes(fieldId);

export const RulesRow = ({
  children = null,
  isLocked = false,
  lockedMessage = '',
  hoverable = true,
  iconName,
  iconOnClick,
  data = null,
  providerName = null,
  providerIdentityId = null,
  containerId = null,
  itemType,
  containerType,
  containerSide,
  linkState = null,
  fieldDetails = {},
  isAction = false,
  name,
  prefix,
}) => {
  const { linkId } = useParams();
  const { parentFieldId, operator, fieldId, value } = data || {};
  const [isTurnOffTestModeModalOpen, setIsTurnOffTestModeModalOpen] = useState(false);
  const areCFsLoaded = useSelector((state) => areCustomFieldsLoaded(state, { containerSide }));

  const providerTerms = (
    <ProviderTermsByName
      providerNameA={providerName}
      containerIdA={containerId}
      plurality="plural"
      termKey="task"
      pcdv3
      itemTypeA={itemType}
    />
  );

  const handleOnIconClick = () => {
    if (linkState !== linkTypes.LINK_STATES.DRAFT && fieldId === fieldTypes.EARLIEST_CREATED_AT) {
      setIsTurnOffTestModeModalOpen(true);
    } else {
      iconOnClick({ operator, fieldId, value });
    }
  };

  if (isAction && isSpecialField(fieldId)) {
    return null;
  }

  if (!data) {
    return (
      <StretchedBox
        $hoverable={hoverable}
        alignItems="flex-start"
        p={[tokens.spacing.s3, tokens.spacing.s4, tokens.spacing.s3, prefix ? 0 : tokens.spacing.s4]}
        fullWidth
      >
        {children}
        {isLocked && (
          <NewTooltip placement="top" title={lockedMessage}>
            <Icon name="lock" kind={Icon.KINDS.SOLID} />
          </NewTooltip>
        )}
        {iconName && !isLocked && <Icon name={iconName} kind={Icon.KINDS.SOLID} onClick={iconOnClick} />}
      </StretchedBox>
    );
  }

  const isTestModeRow = fieldId === fieldTypes.EARLIEST_CREATED_AT;
  const DynamicSubBox = isTestModeRow ? Box : StretchedBox;

  const showSpecialRuleActionIcon =
    ![fieldTypes.INCLUDE_CLOSED_TASKS, fieldTypes.SUBFOLDERS, fieldTypes.DEEP_FILTER_ITEM].includes(fieldId) &&
    !isLocked &&
    areCFsLoaded &&
    iconName;

  return (
    <StretchedBox
      $hoverable={hoverable}
      alignItems="flex-start"
      p={[tokens.spacing.s3, tokens.spacing.s4, tokens.spacing.s3, 0]}
      fullWidth
      borderRadius={tokens.spacing.s3}
      borderSize={isTestModeRow ? 1 : 0}
      bordercolor={isTestModeRow ? tokens.colors.background.neutral.grey : tokens.colors.global.primary.light}
    >
      <DynamicSubBox flexDirection="row" alignItems="flex-start" fullWidth borderRadius={tokens.spacing.s3}>
        <Box m={[0, tokens.spacing.s2, 0, 0]}>
          {prefix || (
            <Box m={[0, 0, 0, tokens.spacing.s4]}>
              <Typography variant={TypographyVariants.H4}>
                {parentFieldId && (
                  <Box as="span" m={[0, tokens.spacing.s3, 0, tokens.spacing.s6]}>
                    <Icon rotation={90} name="level-up" />
                  </Box>
                )}
                AND
              </Typography>
            </Box>
          )}
        </Box>

        {isSpecialField(fieldId) ? (
          <RulesRowSpecialField
            name={name}
            data={data}
            isLocked={isLocked}
            containerId={containerId}
            containerSide={containerSide}
            providerIdentityId={providerIdentityId}
            providerName={providerName}
            fieldDetails={fieldDetails}
            itemType={itemType}
          />
        ) : (
          <RulesRowNormalField
            name={name}
            data={data}
            isLocked={isLocked}
            containerId={containerId}
            containerSide={containerSide}
            providerIdentityId={providerIdentityId}
            providerName={providerName}
            fieldDetails={fieldDetails}
            isAction={isAction}
            itemType={itemType}
            containerType={containerType}
          />
        )}
      </DynamicSubBox>

      {isLocked && (
        <MaxWidthInput p={[tokens.spacing.s2]}>
          <NewTooltip placement="top" title={lockedMessage}>
            <Icon name="lock" kind={Icon.KINDS.SOLID} title="locked" />
          </NewTooltip>
        </MaxWidthInput>
      )}

      {isTestModeRow && (
        <Box p={[tokens.spacing.s2]}>
          <NewTooltip placement="top" title={<TestModeRuleTooltip providerTerms={providerTerms} />}>
            <Icon
              style={{ cursor: 'help' }}
              title="Test mode info"
              name="info-circle"
              kind={Icon.KINDS.SOLID}
              color={tokens.colors.content.info.default}
            />
          </NewTooltip>
        </Box>
      )}

      {showSpecialRuleActionIcon && (
        <MaxWidthInput p={[tokens.spacing.s2]}>
          <Icon
            style={{ cursor: 'pointer' }}
            data-testid={`${parentFieldId ? `${parentFieldId}-` : ''}${fieldId}-${iconName}`}
            name={iconName}
            kind={Icon.KINDS.SOLID}
            onClick={() => handleOnIconClick(data)}
          />
        </MaxWidthInput>
      )}

      {linkState !== linkTypes.LINK_STATES.DRAFT && fieldId === fieldTypes.EARLIEST_CREATED_AT && (
        <TurnOffTestModeModal
          isOpen={isTurnOffTestModeModalOpen}
          onSuccess={() => {
            iconOnClick({ operator, fieldId, value });
            setIsTurnOffTestModeModalOpen(false);
          }}
          onRequestClose={() => setIsTurnOffTestModeModalOpen(false)}
          linkId={linkId}
          taskTerm={providerTerms}
        />
      )}
    </StretchedBox>
  );
};

RulesRow.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node,
  prefix: PropTypes.node,
  isLocked: PropTypes.bool,
  lockedMessage: PropTypes.string,
  hoverable: PropTypes.bool,
  isAction: PropTypes.bool,
  iconName: PropTypes.string,
  iconOnClick: PropTypes.func,
  containerSide: PropTypes.oneOf(['A', 'B']),
  providerName: PropTypes.string,
  providerIdentityId: PropTypes.string,
  containerId: PropTypes.string,
  linkState: PropTypes.string,
  // FIXME: PCDv3 - Remove fieldDetails as we don't need to differenciate when displaying if the field is a filter, set default value or component from mapping
  fieldDetails: PropTypes.shape({
    existingOperators: PropTypes.arrayOf(PropTypes.oneOf(Object.values(pcdFilterOperatorTypes.pcdFilterOperator))),
    canBeDenied: PropTypes.bool,
    canBeAllowed: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  }),
  data: PropTypes.shape({
    operator: PropTypes.oneOf(Object.values(pcdFilterOperatorTypes.pcdFilterOperator)),
    fieldId: PropTypes.string,
    parentFieldId: PropTypes.string,
    // TODO PCDv3 - aug 28
    kind: PropTypes.oneOf([...Object.values(fieldTypes.KINDS), 'options']),
    type: PropTypes.string,
    id: PropTypes.string,
    _id: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])),
    ]),
  }),
  itemType: PropTypes.string.isRequired,
  containerType: PropTypes.string.isRequired,
};
