import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { getCountries } from 'country-state-picker';

import { Box, Button, Icon, Input, Select, tokens, Typography } from '@unitoio/mosaic';

import * as trackingTypes from '~/consts/tracking';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import Amex from '~/images/Amex.svg';
import Discover from '~/images/Discover.svg';
import Interac from '~/images/Interac.svg';
import Mastercard from '~/images/Mastercard.svg';
import PoweredByStripe from '~/images/poweredbystripe.svg';
import Visa from '~/images/Visa.svg';
import { getPlanTier } from '~/utils/getPlanTier';

const Grid = styled.div`
  display: grid;
  gap: ${tokens.spacing.s6};
  grid-template-columns: repeat(4, 1fr);
  padding: ${tokens.spacing.s4};
`;

const Cell = styled.div`
  grid-column: ${(props) => (props.$span ? `span ${props.$span}` : 'span 1')} / auto;
`;

const Footer = styled.footer`
  padding: 0 ${tokens.spacing.s4};
`;

const CreditCards = styled.span`
  img {
    margin-right: ${tokens.spacing.s2};
  }

  &:last-child {
    margin-right: 0;
  }
`;

const ErrorMsg = ({ children }) => (
  <Typography variant="body2" color={tokens.colors.content.destructive.default}>
    {children}
  </Typography>
);

ErrorMsg.propTypes = {
  children: PropTypes.node.isRequired,
};

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: tokens.colors.global.primary.dark,
      '::placeholder': {
        color: tokens.colors.content.neutral.n20,
      },
    },
    invalid: {
      color: tokens.colors.content.destructive.default,
      iconColor: tokens.colors.content.destructive.default,
    },
  },
};

function getCountryOptions() {
  const allCountries = getCountries();

  return allCountries.map(({ name, code }) => ({
    label: name,
    value: code,
  }));
}

const mapFieldToLabel = {
  cardNumber: 'Card number',
  cardCvc: 'CVC',
  cardExpiry: 'Expiration',
  addressZip: 'Postal/Zip code',
  addressCountry: 'Country',
};

// these are directly copied from Mimics <Input/> component
// the component doesn't take children so we have this wrapper for stripe elements
// stripe style object doesn't allow these overrides
// https://stripe.com/docs/js/appendix/style
const StripeElementContainer = styled(Box)`
  height: ${tokens.lineHeight.lh2};
  border: 1px solid ${tokens.colors.content.neutral.n20};
  padding: calc((2.5rem - 16px) / 2);
  margin-top: ${tokens.spacing.s2};
  line-height: ${tokens.lineHeight.lh4};
  border-radius: ${tokens.spacing.s2};
  color: ${tokens.colors.content.neutral.n40};
  width: 100%;
  box-sizing: border-box;
`;

export const CheckoutForm = ({ onSuccess, planId, trackEventNameContext }) => {
  const stripe = useStripe();
  const elements = useElements();
  const trackEvent = useTrackEvent();
  const { handleSubmit, control, formState } = useForm();
  const { isSubmitting, errors } = formState;
  const [paymentInfoError, setPaymentInfoError] = useState(null);

  const onSubmit = async (data) => {
    const tokenData = {
      address_country: data.addressCountry,
      address_zip: data.addressZip.trim(),
    };
    const cardNumber = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(cardNumber, tokenData);

    if (result.error) {
      const { message } = result.error;
      trackEvent(`${trackEventNameContext}_${trackingTypes.BLOCKED}`, {
        ...(planId && { plan_selected: planId }),
        ...(planId && { plan_tier_selected: getPlanTier(planId) }),
        reason: message,
      });
      return setPaymentInfoError(message);
    }

    trackEvent(`${trackEventNameContext}_${trackingTypes.SUBMIT}`, {
      ...(planId && { plan_selected: planId }),
      ...(planId && { plan_tier_selected: getPlanTier(planId) }),
    });

    return onSuccess(result.token);
  };

  const onError = (formErrors) => {
    const missingFields = Object.keys(formErrors).reduce((acc, field) => {
      if (formErrors[field].type === 'required') {
        return [...acc, mapFieldToLabel[field]];
      }
      return acc;
    }, []);

    if (missingFields.length > 0) {
      trackEvent(`${trackEventNameContext}_${trackingTypes.BLOCKED}`, {
        ...(planId && { plan_selected: planId }),
        ...(planId && { plan_tier_selected: getPlanTier(planId) }),
        reason: `${missingFields.join(',')} are missing.`,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Grid>
        <Cell $span="2">
          <Typography variant="body1">{mapFieldToLabel.cardNumber}</Typography>
          <Box m={[tokens.spacing.s2, 0, 0, 0]}>
            <Controller
              render={({ field: { onChange } }) => (
                <StripeElementContainer m={[tokens.spacing.s2, 0, 0, 0]}>
                  <CardNumberElement onChange={onChange} id="card-number-element" options={CARD_ELEMENT_OPTIONS} />
                </StripeElementContainer>
              )}
              control={control}
              name="cardNumber"
              rules={{ required: true }}
            />
            {!!errors?.cardNumber && (
              <ErrorMsg variant="body2" color={tokens.colors.content.destructive.default}>
                Please enter a card number
              </ErrorMsg>
            )}
          </Box>
        </Cell>

        <Cell>
          <Typography variant="body1">{mapFieldToLabel.cardExpiry}</Typography>
          <Box m={[tokens.spacing.s2, 0, 0, 0]}>
            <Controller
              render={({ field: { onChange } }) => (
                <StripeElementContainer m={[tokens.spacing.s2, 0, 0, 0]}>
                  <CardExpiryElement onChange={onChange} id="card-expiry-element" options={CARD_ELEMENT_OPTIONS} />
                </StripeElementContainer>
              )}
              control={control}
              name="cardExpiry"
              rules={{ required: true }}
            />
            {!!errors?.cardExpiry && (
              <ErrorMsg variant="body2" color={tokens.colors.content.destructive.default}>
                Required
              </ErrorMsg>
            )}
          </Box>
        </Cell>

        <Cell>
          <Typography variant="body1">{mapFieldToLabel.cardCvc}</Typography>

          <Box m={[tokens.spacing.s2, 0, 0, 0]}>
            <Controller
              render={({ field: { onChange } }) => (
                <StripeElementContainer m={[tokens.spacing.s2, 0, 0, 0]}>
                  <CardCvcElement id="card-cvc-element" onChange={onChange} options={CARD_ELEMENT_OPTIONS} />
                </StripeElementContainer>
              )}
              control={control}
              name="cardCvc"
              rules={{ required: true }}
            />
            {!!errors?.cardCvc && (
              <ErrorMsg variant="body2" color={tokens.colors.content.destructive.default}>
                Required
              </ErrorMsg>
            )}
          </Box>
        </Cell>

        <Cell $span="2">
          <Typography variant="body1">{mapFieldToLabel.addressCountry}</Typography>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Box m={[tokens.spacing.s2, 0, 0, 0]}>
                <Select
                  value={value}
                  onChange={(val) => {
                    onChange(val);
                  }}
                  options={getCountryOptions()}
                  placeholder="Choose a country"
                  searchable
                  size="md"
                />
              </Box>
            )}
            control={control}
            name="addressCountry"
            rules={{ required: true }}
          />
          {!!errors?.addressCountry && (
            <ErrorMsg variant="body2" color={tokens.colors.content.destructive.default}>
              Please choose a country
            </ErrorMsg>
          )}
        </Cell>

        <Cell $span="2">
          <Typography variant="body1">{mapFieldToLabel.addressZip}</Typography>
          <Controller
            control={control}
            name="addressZip"
            render={({ field }) => <Input id="addressZip" size="large" type="text" maxLength={20} {...field} />}
            rules={{ required: true }}
          />
          {!!errors?.addressZip && (
            <ErrorMsg variant="body2" color={tokens.colors.content.destructive.default}>
              Please enter a zip code
            </ErrorMsg>
          )}
        </Cell>
      </Grid>
      {paymentInfoError && (
        <Box m={[0, 0, 0, tokens.spacing.s4]}>
          <ErrorMsg variant="body2" color={tokens.colors.content.destructive.default}>
            {paymentInfoError}
          </ErrorMsg>
        </Box>
      )}

      <Box p={[1]}>
        <Button block type="submit" disabled={!stripe || isSubmitting}>
          <Box as="span" m={[0, tokens.spacing.s3, 0, 0]}>
            <Icon name="lock" kind={Icon.KINDS.SOLID} />
          </Box>
          Update credit card
        </Button>
      </Box>

      <Footer>
        <img src={PoweredByStripe} height={24} alt="powered by stripe" />
        <CreditCards className="pull-right">
          <img src={Visa} height={24} alt="visa" />
          <img src={Mastercard} height={24} alt="mastercard" />
          <img src={Amex} height={24} alt="amex" />
          <img src={Discover} height={24} alt="discover" />
          <img src={Interac} height={24} alt="interac" />
        </CreditCards>
      </Footer>
    </form>
  );
};

CheckoutForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  trackEventNameContext: PropTypes.string.isRequired,
  planId: PropTypes.string,
};
